import React, {useEffect, useState} from 'react';
import {
  FieldDetailsData,
  FieldDetailsData_field_rotationAreas,
  RotationGroups,
  RotationGroups_rotationGroups,
  Season,
  UpdateRotationArea,
  UpdateRotationAreaStatus,
  UpsertRotationSchemaForRotationArea
} from "../../../../__generated__/types";
import {DataTable} from "primereact/datatable";
import {compact, get, keys, without} from "lodash";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {useMutation, useQuery} from "@apollo/client";
import {
  FieldDetailsQuery,
  UpdateRotationAreaMutation,
  UpdateRotationAreaStatusMutation,
  UpsertRotationSchemaMutation
} from "../FieldManagement.gql";
import {ToggleButton} from "primereact/togglebutton";
import styled from "styled-components";
import {useUserObject} from "../../../../context/UserContext";
import {RotationGroupsQuery} from "../../planning21.gql";
import LocationDropDown from "../../Planning21/components/AddCultivation/components/LocationDropDown/LocationDropDown";
import config from "../../../../config";

interface FieldDetailsProps {
  id: string;
}

const Container = styled.div`
  margin: 0 2vw 2vh;
`;

const FieldDetails = (props: FieldDetailsProps) => {
  const {data: fieldDetailsData, loading} = useQuery<FieldDetailsData>(FieldDetailsQuery, {
    variables: {
      id: props.id
    }
  });

  const {activeFarm} = useUserObject();

  const {data: rotationGroupsData} = useQuery<RotationGroups>(RotationGroupsQuery, {
    variables: {
      farm: activeFarm?.id,
    }
  });

  const [rotationGroups, setRotationGroups] = useState<RotationGroups_rotationGroups[]>([]);
  const [upsertRotationSchemaForRotationArea] = useMutation<UpsertRotationSchemaForRotationArea>(UpsertRotationSchemaMutation);
  const [updateRotationAreaStatus] = useMutation<UpdateRotationAreaStatus>(UpdateRotationAreaStatusMutation);
  const [updateRotationArea] = useMutation<UpdateRotationArea>(UpdateRotationAreaMutation);

  useEffect(() => {
    if (rotationGroupsData && rotationGroupsData.rotationGroups) {
      setRotationGroups(compact(rotationGroupsData.rotationGroups));
    }
  }, [rotationGroupsData]);


  return loading
    ? <div>Loading...</div>
    : fieldDetailsData && fieldDetailsData.field ? <Container>
      <h2>Blokken (teeltwisseling)</h2>
      <DataTable
        value={compact(fieldDetailsData.field.rotationAreas)}
      >
        <Column field={'number'} header={'Nummer'}/>
        <Column field={'active'} header={'Actief'} body={(rotationArea: FieldDetailsData_field_rotationAreas) => {
          return <ToggleButton
            checked={rotationArea.active}
            onChange={(e) => {
              updateRotationAreaStatus({
                variables: {
                  fieldId: props.id,
                  rotationAreaId: rotationArea.id,
                  active: e.value,
                }
              }).then(() => {
              });
            }}
            onIcon="pi pi-check"
            offIcon="pi pi-times"
          />;
        }}/>
        <Column field={'nextRotationArea'} header={'Next'}
                body={(rotationArea: FieldDetailsData_field_rotationAreas) => {
                  return <LocationDropDown
                    onChange={(plotId, rotationAreaIdForPlot) => {
                      if (rotationAreaIdForPlot) {
                        updateRotationArea({
                          variables: {
                            id: rotationArea.id,
                            data: {
                              nextRotationArea: {
                                connect: {
                                  id: rotationAreaIdForPlot
                                }
                              }
                            }
                          },
                        }).then(() => {
                        })

                      } else {
                        updateRotationArea({
                          variables: {
                            id: rotationArea.id,
                            data: {
                              nextRotationArea: {
                                disconnect: true
                              }
                            }
                          },
                          refetchQueries: "active",
                        }).then(() => {
                        })

                      }
                    }}
                    season={config.currentPlanningSeason}
                    rotationAreaId={rotationArea.nextRotationArea?.id}
                    value={undefined}
                  />;
                }}/>
        {without(keys(Season), ...['S_2019_2020', 'S_2020_2021', 'S_2021_2022', 'S_2022_2023']).map(season => {
          return <Column header={season} body={(rotationArea: FieldDetailsData_field_rotationAreas) => {
            if (rotationArea.rotationSchema) {
              let rotationSchema = rotationArea.rotationSchema.find(rotationSchema => rotationSchema.season === season);
              return <Dropdown
                options={rotationGroups}
                value={get(rotationSchema, 'rotationGroup.id')}
                optionValue={'id'}
                optionLabel={'name'}
                onChange={(e) => {
                  upsertRotationSchemaForRotationArea({
                    variables: {
                      fieldId: props.id,
                      rotationAreaId: rotationArea.id,
                      rotationGroupId: e.value,
                      season,
                      rotationSchemaId: get(rotationSchema, 'id', '')
                    }
                  }).then(() => {
                  });
                }}
              />;
            }
          }}/>
        })}
      </DataTable>
    </Container> : <div>No field data</div>;
};

export default FieldDetails;
