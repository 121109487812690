import React from 'react';
import { CultivationEventType } from "../../../../../__generated__/types";

interface StatusColumnProps {
  cultivation: {
    id: string;
    pinned: boolean,
    events: {
      type: CultivationEventType,
      skipped: boolean,
    }[] | null;
  }
}

const StatusColumn = (props: StatusColumnProps) => {
  let hasPlantOrSeedEvent = (props.cultivation.events || []).findIndex(e => e.type === 'PLANT' || (e.type === 'SEED' && !e.skipped)) > -1;
  return <div className='flex align-items-center gap-1'>
    {props.cultivation.pinned &&
      <i className='pi pi-lock text-sm'/>}

    {hasPlantOrSeedEvent &&
      <i className='pi pi-check text-sm'/>}
  </div>
    ;
};

export default StatusColumn;
