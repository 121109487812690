import React, { useEffect, useState } from 'react';
import { Garden } from "./styled-components";
import { isEmpty, sortBy, values } from 'lodash';
import { DEFAULT_FILTERING, GardenFilterValues } from "./planning21.interfaces";
import { DEFAULT_YEARS, usePlanning } from "../planning21-context";
import {
  CROP_PLANNING21_COLUMNS,
  DATE_PLANNING21_COLUMNS,
  Planning21Group,
  PLOT_PLANNING21_COLUMNS
} from "./components/planning21-columns";
import GardenFilters from './components/GardenFilters';
import LocationBasedPlanning from "./views/LocationBasedPlanning";
import CropBasedPlanning from "./views/CropBasedPlanning";
import RotationGroupBasedPlanning from "./views/RotationGroupBasedPlanning";
import { useSearchParams } from "react-router-dom";
import { parse, stringify } from "use-query-params";
import { Sidebar } from "primereact/sidebar";
import AddCultivation from "./components/AddCultivation/AddCultivation";
import DateBasedPlanning from "./views/DateBasedPlanning";

const groups = {
  [Planning21Group.PLOT]: {
    columns: PLOT_PLANNING21_COLUMNS,
    view: (gardenFilters: GardenFilterValues, flat: boolean) => <LocationBasedPlanning flat={flat} gardenFilters={gardenFilters}/>
  },
  [Planning21Group.CROP]: {
    columns: CROP_PLANNING21_COLUMNS,
    view: (gardenFilters: GardenFilterValues) => <CropBasedPlanning gardenFilters={gardenFilters}/>
  },
  [Planning21Group.ROTATION_GROUP]: {
    columns: CROP_PLANNING21_COLUMNS,
    view: (gardenFilters: GardenFilterValues) => <RotationGroupBasedPlanning gardenFilters={gardenFilters}/>
  },
  [Planning21Group.DATE]: {
    columns: DATE_PLANNING21_COLUMNS,
    view: (gardenFilters: GardenFilterValues) => <DateBasedPlanning gardenFilters={gardenFilters}/>
  }
};

export const Planning21 = () => {
  const [gardenFilters, setGardenFilters] = useState<GardenFilterValues>(DEFAULT_FILTERING);
  const [planning21Group, setPlanning21Group] = useState<Planning21Group>(Planning21Group.PLOT);
  const {setYears} = usePlanning();

  const filteredYears = gardenFilters.years;
  useEffect(() => setYears(sortBy(isEmpty(filteredYears) ? DEFAULT_YEARS : filteredYears)), [filteredYears, setYears]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let group = searchParams.get("group");
    if (group) {
      setPlanning21Group(group as Planning21Group);
    }

    let crops = values(parse(searchParams.get("crops")));
    if (crops.length > 0) {
      setGardenFilters(value => ({
        ...value,
        crops: crops
      }));
    }
  }, [searchParams]);

  return <Garden>
    <div>
      <GardenFilters
        value={gardenFilters}
        group={planning21Group}
        columns={groups[planning21Group].columns}
        onFilter={(filterUpdate) => {
          let newVar = {...gardenFilters, ...filterUpdate};
          setSearchParams(value => {
            const stringifyQuery = (query: any) => stringify(query, {arrayFormat: 'brackets', encode: false})
            if (newVar.crops.length > 0) {
              value.set('crops', stringifyQuery(newVar.crops));
            } else {
              value.delete('crops');
            }
            return value;
          })
          setGardenFilters(newVar);
        }}
        onGroup={(column) => {
          setSearchParams(value => {
            value.set('group', column);
            return value;
          })
          setPlanning21Group(column);
        }}
      />
    </div>
    <div className={'cultivations'}>
      {groups[planning21Group].view(gardenFilters, searchParams.get("flat")==='1')}

      {/*{planning21Group === Planning21Group.DATE &&*/}
      {/*chain(cultivations)*/}
      {/*  .values()*/}
      {/*  .orderBy(['startDateUnix'], ['asc'])*/}
      {/*  .map(c => moment(c.startDateUnix, 'X').startOf('isoWeek'))*/}
      {/*  .uniqBy(week => asWeekYear(week))*/}
      {/*  .value()*/}
      {/*  .map(week => <WeekRow week={week} filters={gardenFilters} key={'week' + week.unix()}/>)*/}
      {/*}*/}

      {/*{planning21Group === Planning21Group.ROTATION_GROUP && chain(rotationGroups).sortBy(['name'], ['asc']).value().map(rotationGroup =>*/}
      {/*  <RotationGroupRow key={rotationGroup.id} rotationGroup={rotationGroup} filters={gardenFilters}/>)}*/}
    </div>
  </Garden>;

};

export default () => <Planning21/>;
