import {useMutation} from "@apollo/client/react/hooks";
import {AddToCultivationGroup, GroupCultivations, RemoveFromCultivationGroup} from "../__generated__/types";
import {
  AddToCultivationGroupUpdate,
  GroupCultivationsUpdate,
  RemoveFromCultivationGroupUpdate
} from "../pages/21/planning21.gql";
import {compact} from "lodash";

const useGroupCultivationsMutation = () => {
  return useMutation<GroupCultivations>(GroupCultivationsUpdate, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data) {
              return [...previous, ...compact(data.createCultivationGroup.cultivations)];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });
};

const useAddToCultivationGroupMutation = () => {
  return useMutation<AddToCultivationGroup>(AddToCultivationGroupUpdate, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data?.updateCultivationGroup) {
              return [...previous, ...compact(data.updateCultivationGroup.cultivations)];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });
};


const useRemoveFromCultivationGroupMutation = () => {
  return useMutation<RemoveFromCultivationGroup>(RemoveFromCultivationGroupUpdate, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data?.updateCultivationGroup) {
              return [...previous, ...compact(data.updateCultivationGroup.cultivations)];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });
};

export {useGroupCultivationsMutation, useAddToCultivationGroupMutation, useRemoveFromCultivationGroupMutation};
