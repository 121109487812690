import React, {createRef, useEffect, useRef, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client/react/hooks";
import {
  CreateOrderItem_createOrderItem,
  CultivationsForOrders,
  CultivationsForOrders_cultivations,
  GetOrderItems,
  SellPlant
} from "../../../__generated__/types";
import {DataTable} from 'primereact/datatable';
import {notEmpty} from "../../../shared/utils/stream.utils";
import {Column} from "primereact/column";
import {chain, groupBy, isEmpty, keys, omit, padStart, round, values} from 'lodash';
import {FileUpload} from "primereact/fileupload";
import {CreateOrderItemMutation, CultivationsForOrdersQuery, GetOrderItemsQuery, SellPlantMutation} from "./orders.gql";
import {handleOrderXls} from "./Orders-utils";
import {plantSchemaForCalculations} from "../planning21-context";
import {plantAmount} from "../../../utils/planting.util";
import {InputText} from "primereact/inputtext";
import {momentFromIso8601} from "../../../shared/utils/date.utils";
import DeKoster from "./DeKoster";
import {useUserObject} from "../../../context/UserContext";
import {getSoilBlockSize} from "../../../config";
import Title from "../../../components/ui/Title";
import {Button} from "primereact/button";
import {OverlayPanel} from 'primereact/overlaypanel';
import {Card} from "primereact/card";
import {Tag} from "primereact/tag";

export interface OrderData {
  week: number;
  cultivationId?: string,
  cultivationCropId?: string,
  cultivationCropName?: string,
  cultivationVarietyId?: string,
  cultivationVarietyAlternativeCropName?: string | null,
  cultivationVarietyName?: string | null,
  cultivationVarietyCode?: string | null,
  cultivationVarietySupplierName?: string | null,
  cultivationVarietySoilBlockType?: string | null,
  cultivationAmount?: number,
  orderCropName?: string,
  orderVarietyName?: string,
  orderVarietyCode?: string,
  orderAmount?: number,
  orderSoldTo?: string | null,
  orderId?: string,
  cultivation?: {
    year: number;
    cropName: string;
    amount: number;
    variety?: {};
  }
  plantOrder?: {
    year: number;
    cropName: string;
    amount: number;
    variety: {
      name: string
      code: string;
    };
  }
}

interface Plannings21OrdersProps {

}

type AllWeeks = {
  [key: string]: {
    [key: string]: {
      week: number,
      year: number,
      cultivationCropId?: string,
      cultivationCropName?: string,
      cultivationVarietyId?: string,
      cultivationVarietyAlternativeCropName?: string | null,
      cultivationVarietyName?: string | null,
      cultivationVarietyCode?: string | null,
      cultivationAmount?: number,
      orderCropName?: string,
      orderVarietyName?: string,
      orderVarietyCode?: string,
      orderAmount?: number,
      orderSoldTo?: string | null,
      orderId?: string,
    }
  }
};

const cultivatedAmount = (allWeeks: AllWeeks, weekKey: string, cultivationCropOrVarietyId: string) => {
  const currentWeek = allWeeks[weekKey];
  let currentCultivatedAmount = 0;
  if (currentWeek && currentWeek[cultivationCropOrVarietyId]) {
    const cultivationCropInCurrentWeek = currentWeek[cultivationCropOrVarietyId];
    if (cultivationCropInCurrentWeek.cultivationAmount) {
      currentCultivatedAmount = cultivationCropInCurrentWeek.cultivationAmount;
    }
  }

  return currentCultivatedAmount;
};

const orderAmount = (allWeeks: AllWeeks, weekKey: string, orderCode: string) => {
  const currentWeek = allWeeks[weekKey];
  let currentCultivatedAmount = 0;
  if (currentWeek && currentWeek[orderCode]) {
    const cultivationCropInCurrentWeek = currentWeek[orderCode];
    if (cultivationCropInCurrentWeek.orderAmount) {
      currentCultivatedAmount = cultivationCropInCurrentWeek.orderAmount;
    }
  }

  return currentCultivatedAmount;
};

const matchingOrderForVariety = (cultivationVariety: {
  variety: { code: string | null, id: string }
}, allWeeks: AllWeeks, weekKey: string) => {
  let matchingOrder: any;
  if (cultivationVariety.variety.code && allWeeks[weekKey]) {
    matchingOrder = allWeeks[weekKey][cultivationVariety.variety.code];

    if (!matchingOrder) {
      return allWeeks[weekKey][cultivationVariety.variety.id];
    }
  }

  return matchingOrder;
};

type Status =
  | "1-plannedCultivationWithOrderShortage"
  | "2-orderedPlantsWithoutCultivation"
  | "3-plannedCultivationWithoutVariety"
  | "4-plannedAndOrderedCultivation";

type StatusConfigItem = { label: string, severity: 'warning' | 'success' | 'danger' | 'info', icon: string }

const statusConfig: { [key in Status]: StatusConfigItem } = {
  '1-plannedCultivationWithOrderShortage': {
    severity: 'danger',
    label: "Te bestellen",
    icon: "pi pi-times",
  },
  '2-orderedPlantsWithoutCultivation': {
    severity: 'warning',
    label: "Te plannen",
    icon: "pi pi-exclamation-triangle",
  },
  '3-plannedCultivationWithoutVariety': {
    severity: 'warning',
    label: "Geen variëteit gekend",
    icon: "pi pi-exclamation-triangle",
  },
  '4-plannedAndOrderedCultivation': {
    severity: 'success',
    label: "Ok",
    icon: "pi pi-check",
  },
};

const status = (x: any): Status | null => {

  if ((x.orderAmount < x.cultivationAmount && Math.abs(x.orderAmount - x.cultivationAmount) > 50)
    || !x.orderVarietyCode) {
    return "1-plannedCultivationWithOrderShortage";
  }

  if (!x.cultivationVarietyCode && !(typeof (x.orderSoldTo) == 'string' && x.orderSoldTo.length > 0)) {
    return "2-orderedPlantsWithoutCultivation";
  }

  if (!x.cultivationVarietyCode && x.cultivationCropName) {
    return "3-plannedCultivationWithoutVariety";
  }

  // if (!x.cultivationVarietyCode && (typeof (x.orderSoldTo) == 'string' && x.orderSoldTo.length > 0)) {
  //   "VERKOCHT";
  // }

  if ((Math.abs(x.orderAmount - x.cultivationAmount) < 50) || x.orderAmount >= x.cultivationAmount) {
    return "4-plannedAndOrderedCultivation";
  }

  return null;
};

const Planning21Orders = (props: Plannings21OrdersProps) => {
  const {activeFarm} = useUserObject();
  const {data} = useQuery<GetOrderItems>(GetOrderItemsQuery, {variables: {farm: activeFarm?.id}});
  const {data: cultivationsForOrders} = useQuery<CultivationsForOrders>(CultivationsForOrdersQuery, {variables: {farm: activeFarm?.id}});
  const [createOrderItem] = useMutation<CreateOrderItem_createOrderItem>(CreateOrderItemMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          orderItems: (previous) => {
            if (data) {
              return [...previous, data];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });

  const fileUploadRef = createRef<FileUpload>();
  const [combinedData, setCombinedData] = useState<OrderData[]>([]);

  const [cultivations, setCultivations] = useState<CultivationsForOrders_cultivations[]>([]);
  const [showDeKosterOrderDownload, setShowDeKosterOrderDownload] = useState<boolean>(false);
  const [showUploadOrder, setShowUploadOrder] = useState<boolean>(true);
  const op = useRef<OverlayPanel>(null);

  useEffect(() => {
    if (cultivationsForOrders && cultivationsForOrders.cultivations) {
      setCultivations(cultivationsForOrders.cultivations.filter(notEmpty));
    }
  }, [cultivationsForOrders]);

  useEffect(() => {
    const orderData: AllWeeks = chain(data ? data.orderItems : [])
      .filter(notEmpty)
      .filter(order => order.deliveryYear > 2023)
      .reduce((allWeeks: AllWeeks, order) => {

        const weekKey = `${order.deliveryYear}-${padStart(`${order.deliveryWeek}`, 2)}`;
        const currentWeek = allWeeks[weekKey];

        return {
          ...allWeeks,
          [weekKey]: {
            ...currentWeek,
            [order.code]: {
              week: order.deliveryWeek,
              year: order.deliveryYear,
              orderId: order.id,
              orderCropName: order.cropName,
              orderVarietyName: order.name,
              orderVarietyCode: order.code,
              orderAmount: orderAmount(allWeeks, weekKey, order.code) + order.amount,
              orderFilename: order.filename,
              orderSoldTo: order.soldTo,
              orderSoilBlockType: order.soilBlockType,
            }
          }
        };
      }, {})
      .value();

    const cultivationData = chain(cultivations)
      //.filter(inOrAfterYear(ORDER_START_YEAR))
      //.filter(cultivation => cultivation.cropTiming.type === PlantOrSeed.PLANT && cultivation.transplant !== null && cultivation.transplant)
      .reduce((allWeeks: AllWeeks, cultivation) => {
        const plantSchema = plantSchemaForCalculations(cultivation);
        const plantSchemaForParent = cultivation.parent && plantSchemaForCalculations(cultivation.parent);
        if (!cultivation.varieties || isEmpty(cultivation.varieties)) {
          let cultivationIsoWeek = momentFromIso8601(cultivation.startDate).isoWeek();
          cultivationIsoWeek = cultivationIsoWeek % 2 === 0 ? cultivationIsoWeek : cultivationIsoWeek - 1;

          const year = momentFromIso8601(cultivation.startDate).year();
          const weekKey = `${year}-${padStart(`${cultivationIsoWeek}`, 2)}`;

          const currentWeek = allWeeks[weekKey];
          let cultivationAmount = cultivatedAmount(allWeeks, weekKey, cultivation.crop.id) +
            plantAmount(plantSchema?.plantSchema, cultivation.length, cultivation.plot.rotationArea.field.bedWidth, plantSchemaForParent?.plantSchema);
          return {
            ...allWeeks,
            [weekKey]: {
              ...currentWeek,
              [cultivation.crop.id]: {
                week: cultivationIsoWeek,
                year: year,
                cultivationCropId: cultivation.crop.id,
                cultivationCropName: cultivation.crop.name,
                cultivationAmount,
              }
            }
          };

        } else {
          const startDate = momentFromIso8601(cultivation.startDate);
          const cultivationIsoWeek = startDate.isoWeek();

          return cultivation.varieties.reduce((allWeeks, cultivationVariety) => {
            const year = startDate.year();

            let cultivationWeekKey = `${year}-${padStart(`${cultivationIsoWeek}`, 2)}`;
            const orderInSameWeekAsCultivation = matchingOrderForVariety(cultivationVariety, allWeeks, cultivationWeekKey);
            const week = (cultivationIsoWeek % 2 === 0 || orderInSameWeekAsCultivation) ? cultivationIsoWeek : cultivationIsoWeek - 1;
            const weekKey = `${year}-${padStart(`${week}`, 2)}`;

            const matchingOrder = matchingOrderForVariety(cultivationVariety, allWeeks, weekKey);

            let updatedWeeks = {...allWeeks};

            const matchingWeekCultivationVariety = allWeeks[weekKey] ? allWeeks[weekKey][cultivationVariety.variety.id] : {};
            let cultivatedAmountValue = cultivatedAmount(updatedWeeks, weekKey, cultivationVariety.variety.id);
            let plantAmountValue = plantAmount(
              plantSchema?.plantSchema,
              cultivation.length * cultivationVariety.percentage,
              cultivation.plot.rotationArea.field.bedWidth,
              plantSchemaForParent?.plantSchema,
            );
            updatedWeeks = {
              ...allWeeks,
              [weekKey]: {
                ...(matchingOrder ? omit(allWeeks[weekKey], matchingOrder.orderVarietyCode) : allWeeks[weekKey]),
                [cultivationVariety.variety.id]: {
                  ...matchingWeekCultivationVariety,
                  ...matchingOrder,
                  week,
                  year: year,
                  cultivationId: cultivation.id,
                  cultivationCropId: cultivation.crop.id,
                  cultivationCropName: cultivation.crop.name,
                  cultivationVarietyId: cultivationVariety.variety.id,
                  cultivationVarietySupplierName: cultivationVariety.variety.supplier?.name,
                  cultivationVarietyName: cultivationVariety.variety.name,
                  cultivationVarietyAlternativeCropName: cultivationVariety.variety.alternativeCropName,
                  cultivationVarietyCode: cultivationVariety.variety.code,
                  cultivationAmount: cultivatedAmountValue + plantAmountValue,
                  cultivationVarietySoilBlockType: cultivationVariety.variety.soilBlockType,
                }
              }
            };
            return updatedWeeks;
          }, allWeeks);
        }
      }, orderData)
      .mapValues(cultivations => values(cultivations))
      .values()
      .flatten()
      .map((x) => ({...x, status: status(x)}))
      .orderBy(['week', 'status'], ['asc', 'asc'])
      .value();

    setCombinedData(cultivationData);
  }, [cultivations, data]);

  let userContextValue = useUserObject();

  const statusBadge = (x: { status: Status }, value?: string) => {
    let status = statusConfig[x.status];
    return <Tag className="mr-2" icon={status.icon} severity={status.severity}>{value || status.label}</Tag>;
  };

  return <div className="mx-2 md:mx-3 lg:mx-4 xl:mx-6">
    <div className="flex justify-content-between align-items-center">
      <Title>Bestellingen</Title>

      <div className="flex flex-column align-items-end">
        <Button
          className="py-1"
          size="small"
          link
          icon="pi pi-file-export"
          iconPos="right"
          label="Maak bestelling .xls (De Koster)"
          onClick={() => {
            setShowDeKosterOrderDownload(!showDeKosterOrderDownload);
          }}
        />
        <Button
          className="py-1"
          size="small"
          link
          icon="pi pi-file-import"
          iconPos="right"
          label="Importeer bestelbon (De Koster)"
          onClick={(e) => {
            op.current?.toggle(e);
          }}
        />
      </div>
    </div>

    {showDeKosterOrderDownload && <DeKoster data={combinedData} onClose={() => setShowDeKosterOrderDownload(false)}/>}

    <OverlayPanel ref={op}>
      <FileUpload style={{width: 300}} ref={fileUploadRef} customUpload name="bestelling" auto uploadHandler={(e) => {
        if (userContextValue.activeFarm?.id) {
          handleOrderXls(e.files[0], userContextValue.activeFarm?.id, createOrderItem);
        } else {
          console.error('Farm unknown for order import');
        }
        fileUploadRef.current && fileUploadRef.current.clear();
      }}/>
    </OverlayPanel>

    <Card>
      {combinedData &&
        <DataTable
          //tableStyle={{width: '900px'}}
          className="order-table p-datatable-sm"
          tableStyle={{height: '1px'}}
          groupRowsBy={'week'}
          value={combinedData}
          // expandableRowGroups
          rowGroupMode={'subheader'}
          rowGroupHeaderTemplate={(orderItem: OrderData) => {
            let itemsForWeek = groupBy(combinedData, 'week')[orderItem.week];
            let itemsByStatus = groupBy(itemsForWeek, 'status');
            return <div className="flex align-items-center">
              <div className="p-3 font-medium text-lg">Week {orderItem.week}</div>
              {keys(itemsByStatus).map(status => statusBadge({status: status as Status}, `${itemsByStatus[status].length}`)
              )}
            </div>;
          }
          }
          rowGroupFooterTemplate={() => <></>}
          rowClassName={(x: any) => {
            const orderIsOk = (x.orderAmount - x.cultivationAmount > -x.cultivationAmount * 0.05) || x.orderAmount >= x.cultivationAmount;
            return ({
              'needs-planning': !x.cultivationVarietyCode,
              'sold-to': (typeof (x.orderSoldTo) === 'string' && x.orderSoldTo.length > 0),
              'needs-order': (x.orderAmount < x.cultivationAmount && (x.orderAmount - x.cultivationAmount) < -x.cultivationAmount * 0.9) || !x.orderVarietyCode,
              'ok': orderIsOk,
            });
          }
          }
        >

          <Column header="Status" body={(x: any) => {
            return statusBadge(x);
          }}/>

          <Column
            header="Gewas"
            body={(x: any) => {
              return <div>
                {x.cultivationVarietyAlternativeCropName ? x.cultivationVarietyAlternativeCropName : x.cultivationCropName}
                {!x.cultivationCropName && x.orderCropName}
                {x.cultivationVarietyCode && <div className="text-xs text-color-secondary">
                  {`${x.cultivationVarietyCode ? x.cultivationVarietyCode : ''} - ${x.cultivationVarietyName ? x.cultivationVarietyName : ''}`}
                </div>}
                {(x.orderVarietyCode && !x.cultivationVarietyCode) && <div className="text-sm p-error font-bold">
                  {`${x.orderVarietyCode ? x.orderVarietyCode : ''} - ${x.orderVarietyName ? x.orderVarietyName : ''}`}
                </div>}
              </div>;
            }}/>

          {/*| "1-plannedCultivationWithOrderShortage"*/}
          {/*| "2-orderedPlantsWithoutCultivation"*/}
          {/*| "3-plannedCultivationWithoutVariety"*/}
          {/*| "4-plannedAndOrderedCultivation"*/}

          <Column header="Aantallen" headerClassName="flex justify-content-center" field={'cultivationAmount'}
                  body={(x: any) => {

                    const orderIsOk = (x.orderAmount - x.cultivationAmount > -x.cultivationAmount * 0.05) || x.orderAmount >= x.cultivationAmount;


                    let soilBlockSize = getSoilBlockSize(x.cultivationVarietySoilBlockType);
                    let orderSoilBlockSize = getSoilBlockSize(x.orderSoilBlockType);
                    const nrOfCratesOrdered = orderSoilBlockSize ? round(x.orderAmount / orderSoilBlockSize.nrOfPlants, 0) : undefined;
                    const nrOfCratesPlanned = soilBlockSize ? round(x.cultivationAmount / soilBlockSize.nrOfPlants, 0) : undefined;
                    const orderShortage = (x.cultivationAmount || 0) - (x.orderAmount||0);
                    const orderShortageInCrates = (nrOfCratesPlanned || 0) - (nrOfCratesOrdered || 0);

                    return <div>
                      {x.status === "1-plannedCultivationWithOrderShortage" && <div className='text-red-700 font-bold'>
                        {orderShortageInCrates > 0 && `${orderShortageInCrates} bak${orderShortageInCrates > 1 ? 'ken' : ''}`}
                        {!soilBlockSize && `${orderShortage} planten`}
                        &nbsp;te bestellen
                      </div>}
                      {x.orderAmount && <div className='text-green-400'>
                        {nrOfCratesOrdered && `${nrOfCratesOrdered} bak${nrOfCratesOrdered > 1 ? 'ken' : ''}`}
                        {!orderSoilBlockSize && `${x.orderAmount} planten`}
                        &nbsp;besteld
                        {(x.cultivationAmount / x.orderAmount) < 0.95 ? <span className='font-bold'> ({orderShortage} overschot)</span> : ''}
                        {(x.cultivationAmount / x.orderAmount) > 1.05 ? <span className='font-bold text-red-700'> ({orderShortage} tekort)</span> : ''}
                      </div>}
                      {orderShortage !== 0 && x.status !=='2-orderedPlantsWithoutCultivation' && <div className='text-gray-500'>
                        {nrOfCratesPlanned && `${nrOfCratesPlanned} bak${nrOfCratesPlanned > 1 ? 'ken' : ''}`}
                        {!soilBlockSize && `${x.cultivationAmount} planten`}
                        &nbsp;ingepland
                      </div>}
                    </div>;
                  }}/>
          <Column header="Aantallen" headerClassName="flex justify-content-center"
                  field={'cultivationAmount'}
                  body={(x: any) => {
                    const orderShortage = x.orderAmount - (x.cultivationAmount || 0);

                    let soilBlockSize = getSoilBlockSize(x.cultivationVarietySoilBlockType);
                    let orderSoilBlockSize = getSoilBlockSize(x.orderSoilBlockType);
                    return <div>
                      <div className="h-full flex flex-column align-items-stretch">
                        <div className="grid w-full">
                          <div
                            className="col-6 flex justify-content-start flex-column text-right align-items-end">
                            {soilBlockSize && x.cultivationAmount && <>
                              <div>{round(x.cultivationAmount / soilBlockSize.nrOfPlants, 0)} bakken</div>
                              {x.cultivationAmount &&
                                <div
                                  className="text-xs text-color-secondary">{x.cultivationAmount} planten</div>}
                            </>}
                            {!soilBlockSize && x.cultivationAmount && <>
                              <div>{x.cultivationAmount} planten</div>
                            </>}
                          </div>
                          <div className="col-6 flex justify-content-start flex-column pl-2">
                            {orderSoilBlockSize && x.orderAmount && <>
                              <div>{round(x.orderAmount / orderSoilBlockSize.nrOfPlants, 0)} bakken</div>
                              {x.orderAmount &&
                                <div className="text-xs text-color-secondary">{x.orderAmount} planten</div>}
                            </>}
                            {!orderSoilBlockSize && x.orderAmount && <>
                              <div>{x.orderAmount} planten</div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>;
                  }}/>
          <Column header="Acties" body={(x: any) => {
            const orderShortage = (x.orderAmount || 0) - (x.cultivationAmount || 0);

            let soilBlockSize = getSoilBlockSize(x.cultivationVarietySoilBlockType);

            let shortageInOrderUnit = soilBlockSize ? round(Math.abs(orderShortage) / soilBlockSize.nrOfPlants, 0) : 0;

            return <div
              className="text-sm text-center"
              style={{color: (orderShortage < 0) ? 'rgba(244,143,177)' : 'rgba(129,199,132)'}}>
              {soilBlockSize &&
                <div>
                  {orderShortage !== 0 && <div className="font-bold">
                    {shortageInOrderUnit} bakken {orderShortage < 0 ? ' te bestellen' : ' te plannen'}
                  </div>}
                  {<div>{Math.abs(orderShortage)} planten</div>}
                </div>
              }
              {!soilBlockSize && <div className="font-bold">{Math.abs(orderShortage)} planten</div>}
            </div>;
          }}/>

          {/*<Column field={'orderFilename'}/>*/}


          {/*<Column*/}
          {/*  body={(x: OrderData) => {*/}
          {/*    return <div>{`${x.orderVarietyCode ? x.orderVarietyCode : ''} - ${x.orderVarietyName ? x.orderVarietyName : ''}`}</div>;*/}
          {/*  }}/>*/}


          {/*<Column field={'orderSoldTo'}*/}
          {/*        body={(order: OrderData) => {*/}
          {/*          return <SoldTo key={order.orderId} order={order}/>;*/}
          {/*        }}*/}
          {/*/>*/}
        </DataTable>
      }
    </Card>
  </div>
    ;
};

const SoldTo = (props: { order: OrderData }) => {
  const [value, setValue] = useState<string>(props.order.orderSoldTo || '');
  const [editable, setEditable] = useState<boolean>(!(typeof (value) == 'string' && value.length > 0));
  const [updateOrderItem] = useMutation<SellPlant>(SellPlantMutation);

  return <div>
    {!editable && <span onClick={() => setEditable(true)}>{value || '-'}</span>}
    {editable && <InputText
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      onKeyDown={(e: any) => {
        if (e.keyCode === 13) {
          updateOrderItem({
            variables: {
              id: props.order.orderId,
              soldTo: e.target.value || '',
            }
          })
            .then(r => {
              setEditable(false);
            });
        }
      }}
    />}
  </div>;
};

export default Planning21Orders;
