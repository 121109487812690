import React, { useEffect, useState } from 'react';
import { CropDetails_crop_cropTimings, CropTimingsForCrop_cropTimings, FieldType, PlantOrSeed, Source } from "../../../../../../__generated__/types";
import Timeline from '../../../../Planning21/planning21-timeline';
import styled from "styled-components";
import { Moment } from "moment";
import { compact, flattenDeep, map, range } from 'lodash';
import Icon from "../../../../planning21-icon";
import GardenFilters from '../../../../Planning21/components/GardenFilters';
import { Planning21Column, Planning21Group } from "../../../../Planning21/components/planning21-columns";
import { cropTimingToTimelineData } from "../../../../Planning21/planning21-mutations";
import { DEFAULT_FILTERING } from "../../../../Planning21/planning21.interfaces";
import { nl } from "../../../../../../shared/utils/date.utils";

export type CropTimingColumn = 'type' | 'cultivationMethod' | 'timeline';

export interface CropTimingListingProps {
  cropTimings: CropDetails_crop_cropTimings[];
  value?: string | undefined;
  filters?: { type: FieldType | undefined, plantOrSeed: PlantOrSeed | undefined };
  startDate?: Moment | undefined;
  year: number;
  years: number[];
  onChange: (cropTiming: CropDetails_crop_cropTimings | undefined) => void;
  showPreseeding?: boolean;
  visibleColumns: CropTimingColumn[];
  varietyId?: string;
  onStartWeekClick?: (cropTimingId: string, cropTimings: CropTimingsForCrop_cropTimings[], startWeek: Moment) => void;
  onHarvestEndWeekClick?: (cropTimingId: string, cropTimings: CropTimingsForCrop_cropTimings[], harvestEndWeek: Moment) => void;
  weekValues?: { startWeek?: Moment, harvestEndWeek?: Moment };
  hideFilters?: boolean;
  allowWeekSelection?: boolean;
}

export interface CropTimingListingColumn extends Planning21Column<CropDetails_crop_cropTimings> {
  name: CropTimingColumn;
}

const Container = styled.div`

`;

const Cell = styled.div`
    padding: 0 4px;
`;

export const CropTiming = styled.div<{ columns: any[] }>`
  display: grid;
  grid-template-columns: ${({columns}) => map(columns, 'width').join(' ')};
  border-bottom: 1px solid var(--surface-d);
  position: relative;

  &:hover {
    background-color: var(--secondary-color);
  }

//       &:hover,
   &.selected,
   &.highlighted {
     &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: ' ';
      background-color: var(--secondary-color);
     }
   }

   &.selected {
    &::before {
      border: 3px solid var(--primary-color);
      background: var(--secondary-color);
    }
  }
`;

const filterByFieldType = (cropTiming: CropDetails_crop_cropTimings, type: FieldType | undefined) => {
  if (type) {
    const cultivationMethod = type === FieldType.OPEN ? 'Openlucht' : 'Beschut';
    return cropTiming.cultivationMethod === cultivationMethod;
  }
  return true;
}

const CropTimingList = (props: CropTimingListingProps) => {
  const [selectedCropTiming, setSelectedCropTiming] = useState<string>();

  useEffect(() => {
    setSelectedCropTiming(props.value)
  }, [props.value]);

  const columns: CropTimingListingColumn[] = [
    {
      key: 'source',
      label: 'Bron',
      name: 'type',
      width: '80px',
      body: (cropTiming: CropDetails_crop_cropTimings) => {
        return <Cell
          key={`source-${cropTiming.id}`}>{cropTiming.source === Source.GROENTEGEWELD ? 'GGW' : 'I'}</Cell>
      },
    },
    {
      key: 'type',
      label: 'Type',
      name: 'type',
      width: '100px',
      body: (cropTiming: CropDetails_crop_cropTimings) => {
        return cropTiming.type &&
          <Cell key={`type-${cropTiming.id}`}><Icon name={cropTiming.type.toLowerCase()}/></Cell>
      },
    },
    {
      key: 'location',
      label: 'Locatie',
      name: 'cultivationMethod',
      width: '100px',
      body: (cropTiming: CropDetails_crop_cropTimings) => {
        return cropTiming.cultivationMethod &&
          <Cell key={`cultivationMethod-${cropTiming.id}`}><span>{cropTiming.cultivationMethod}</span></Cell>
      },
    },
    {
      key: 'timeline',
      label: 'Tijdslijn',
      name: 'timeline',
      width: '1fr',
      body: (cropTiming: CropDetails_crop_cropTimings, selected, onSelectionChange, years) => {
        if (cropTiming.startWeekNumberFrom && cropTiming.startWeekNumberUntil && cropTiming.endWeekNumberFrom && cropTiming.endWeekNumberUntil) {
          const timelineData = cropTimingToTimelineData(cropTiming, undefined, undefined, props.year);
          return <Timeline
            allowWeekSelection
            key={`timeline-${cropTiming.id}`}
            years={years}
            id={cropTiming.id}
            timelineData={timelineData}
            showPreseeding
            showFlameweeding={false}
            occupationRatio={1}
            onStartWeekClick={startWeek => props.onStartWeekClick && props.onStartWeekClick(cropTiming.id, props.cropTimings as CropTimingsForCrop_cropTimings[], startWeek)}
            onHarvestEndWeekClick={(harvestEndWeek) => props.onHarvestEndWeekClick && props.onHarvestEndWeekClick(cropTiming.id, props.cropTimings as CropTimingsForCrop_cropTimings[], harvestEndWeek)}
            selectedStartWeek={cropTiming.id === props.value && props.weekValues ? props.weekValues.startWeek : undefined}
            selectedHarvestEndWeek={cropTiming.id === props.value && props.weekValues ? props.weekValues.harvestEndWeek : undefined}
          />
        }
      },
      subHeaders: () =>
        flattenDeep(props.years.map(year =>
          range(1, 13)
            .map(t => ({
              key: `${year}-${t - 1}`,
              value: `${year}-${t - 1}`,
              label: nl.monthNamesShort[t - 1],
              width: '1fr'
            }))
        )),
    },
  ];

  return <Container>
    <GardenFilters
      value={DEFAULT_FILTERING}
      group={Planning21Group.PLOT}
      noFilters
      columns={columns.filter(c => props.visibleColumns.includes(c.name))}
      onFilter={() => console.log('filter')}
    />
    {compact(props.cropTimings)
      .filter(cropTiming => filterByFieldType(cropTiming, props.filters?.type))
      .filter(cropTiming => {
        if (props.filters && props.filters.plantOrSeed) {
          return cropTiming.type === props.filters.plantOrSeed;
        } else {
          return true;
        }
      })
      .map(cropTiming => {
        return <CropTiming
          columns={columns.filter(c => props.visibleColumns.includes(c.name))}
          key={cropTiming.id}
          className={[
            selectedCropTiming === cropTiming.id ? 'selected' : '',
            (cropTiming.varieties || []).findIndex(v => v.id === props.varietyId) > -1 ? 'highlighted' : '',
          ].join(' ')}
          onClick={() => {
            const updatedSelectedCropTiming = selectedCropTiming === cropTiming.id ? undefined : cropTiming.id;
            setSelectedCropTiming(updatedSelectedCropTiming);
            props.onChange(updatedSelectedCropTiming ? cropTiming : undefined);
          }}>

          {columns
            .filter(c => props.visibleColumns.includes(c.name))
            .map(visibleColumn => visibleColumn.body && visibleColumn.body(cropTiming, false, () => {
            }, props.years, undefined))}
        </CropTiming>;
      })}
  </Container>;
};

export default CropTimingList;
