import {GardenFilterValues} from "../planning21.interfaces";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useQuery} from "@apollo/client";
import {
  CultivationsForDate,
  CultivationsForDateVariables,
  CultivationsForRotationGroup_cultivations,
  CultivationWhereInput
} from "../../../../__generated__/types";
import {CultivationsForDateQuery} from "../../planning21.gql";
import {momentFromYyyyW} from "../../../../shared/utils/date.utils";
import {chain, flatten, isEmpty, toInteger} from "lodash";
import CultivationRow from "../components/CultivationRow";
import OutsideAlerter from "../../../../shared/components/outside-alerter";
import {GroupRow} from "../components/planning21-cultivation-group-row";
import {Planning21HorizontalGrid, TextButton} from "../styled-components";
import {DATE_PLANNING21_COLUMNS} from "../components/planning21-columns";
import AddCultivation from "../components/AddCultivation/AddCultivation";
import {Button} from "primereact/button";

const RotationGroupInfo = styled.div`
  padding: 2px 4px;
  display: grid;
  grid-template-columns: 1fr max-content;
`;

const TimelineHolder = styled.div`
  height: 80px;
  background: var(--surface-c);
  border: 1px solid var(--surface-d);
  border-bottom: 0;
  position: relative;
  overflow-x: clip;

  .loading {
    padding: 2px;
    font-size: 0.9rem;
  }

  > .timeline {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

interface DateRowProps {
  weekNumber: number;
  filters: GardenFilterValues;
}

const columns = DATE_PLANNING21_COLUMNS;
const DateGroupRow = (props: DateRowProps) => {
  const [showCultivations, setShowCultivations] = useState<boolean>(props.weekNumber === 8);
  const [filteredCultivations, setFilteredCultivations] = useState<CultivationsForRotationGroup_cultivations[]>([]);

  const {weekNumber, filters} = props;

  const periodsPerYear:CultivationWhereInput[][] = filters.years.map((year): CultivationWhereInput[] => {
    const from = momentFromYyyyW(`${year}-${weekNumber}`).startOf('isoWeek');
    const to = momentFromYyyyW(`${year}-${weekNumber + 1}`).startOf('isoWeek');
    return ([
      {startDate_gte: from, startDate_lte: to},
    ]);
  });

  const variables: CultivationsForDateVariables = {
    fields: filters.fields,
    cultivationWhereInput: {
      OR: flatten(periodsPerYear)
    },
    // from: `${asIso8601(moment(min(filters.years), 'YYYY').startOf('y'))}`,
    // to: `${asIso8601(moment(max(filters.years), 'YYYY').endOf('y'))}`,
  };
  const {data,refetch} = useQuery<CultivationsForDate>(CultivationsForDateQuery, {
    variables,
  });

  useEffect(() => {
    if (data) {
      if (data.cultivations) {
        const cultivations = chain(data.cultivations)
          .compact()
          .value();

        setFilteredCultivations(
          cultivations
            .filter((cultivation: CultivationsForRotationGroup_cultivations) => isEmpty(filters.crops) || (filters.crops.includes(cultivation.crop.id)))
        );
      }
    }
  }, [data, filters]);

  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

    return <OutsideAlerter onOutsideClicked={() => setMode('read')}>
      <GroupRow className={mode === 'create' ? 'p-shadow-3' : ''}
                onClick={() => setShowCultivations(!showCultivations)}>
        <Planning21HorizontalGrid columns={[
          {
            width: `${chain(columns)
              .filter(c => c.width.endsWith('px'))
              .map(c => toInteger(c.width.replace('px', '')))
              .sum()
              .value()}px`,
          },
          {
            width: '1fr'
          }
        ]}>
          <RotationGroupInfo>
            <span>w{weekNumber}</span>
            <Button
              link
              size="small"
              icon="pi pi-refresh"
              className="p-0 m-0"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                refetch().then((x) =>x);
              }}/>
          </RotationGroupInfo>
        </Planning21HorizontalGrid>
      </GroupRow>

      {showCultivations && <>
        {filteredCultivations.map((cultivation) =>
          <CultivationRow
            editable={true}
            plotId={cultivation.plot.id}
            columns={columns}
            groupingColumn={columns[2]}
            cultivation={cultivation}
            key={`${weekNumber}-${cultivation.id}`}
          />)}
        {mode === 'create' && <AddCultivation onClose={() => setMode('read')}/>}
        <TextButton onClick={() => setMode('create')} style={{gridArea: 'add'}}>
          + teelt toevoegen
        </TextButton>
      </>
      }
    </OutsideAlerter>;

};

export default DateGroupRow;
