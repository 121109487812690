import {FilterOptionValues} from "../planning21.interfaces";
import {asDdMmm, momentFromIso8601, nl} from "../../../../shared/utils/date.utils";
import React from "react";
import styled from "styled-components";
import Timeline from "../planning21-timeline";
import {compact, flattenDeep, range, sortBy} from "lodash";
import {
  CultivationsForCrop_cultivations,
  CultivationsForPlot_cultivations,
  CultivationsForRotationGroup_cultivations,
  PlantOrSeed
} from "../../../../__generated__/types";
import {plantSchemaForCalculations, SEASONS} from "../../planning21-context";
import {nrOfRows, plantAmount} from "../../../../utils/planting.util";
import {
  calculateHarvestWindow,
  calculateSowingOrPlantingWindow,
  cropTimingToTimelineData
} from "../planning21-mutations";
import CultivationTasks from "./CultivationTasks/CultivationTasks";
import StatusColumn from "./columns/StatusColumn";
import WarningColumn from "./columns/WarningColumn";
import SelectionColumn from "./columns/SelectionColumn";
import EventsColumn from "./columns/EventsColumn";

export interface Planning21Column<T> {
  value?: string,
  selectable?: boolean,
  label: string,
  key: string,
  width: string,
  filterField?: keyof FilterOptionValues,
  filterOptionValuesProperty?: keyof FilterOptionValues,
  sortFields?: string[],
  groupingField?: Planning21Group;
  body?: (props: T,
          selected: boolean | undefined,
          onSelectionChange: ((selected: boolean) => void) | undefined,
          years: number[],
          groupPosition: undefined|'first'|'middle'|'last',
          ) => any;
  subHeaders?: (years: number[]) => Planning21Column<T>[];
}

export enum Planning21Group {
  PLOT = 'Plot',
  CROP = 'Crop',
  ROTATION_GROUP = 'RotationGroup',
  DATE = 'Date',
}

export const CultivationCropNamings = styled.div`
  //display: grid;
  //grid-template-columns: max-content 1fr;
  //align-items: center;

  *:first-child {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  *:last-child {
    //padding-left: 4px;
    font-style: italic;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Lengths = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 2fr;
  justify-content: right;
  text-align: right;
  grid-gap: 4px;

  > *:last-child {
    text-align: left;
    padding: 0 4px;
  }
`;

export const statusColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'status',
  label: '',
  width: '40px',
  body: (cultivation) => {
    return <StatusColumn key={`warnings-${cultivation.id}`} cultivation={cultivation}/>;
  },
};

export const selectionColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'selection',
  selectable: true,
  label: '',
  width: '30px',
  body: (cultivation, selected, onSelectionChange, years) => {
    return <SelectionColumn key={`selection-${cultivation.id}`} cultivation={cultivation} onChange={onSelectionChange}
                            selected={selected}/>;
  },
};

export const warningColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'warnings',
  label: '',
  width: '30px',
  body: (cultivation) => {
    return <WarningColumn key={`events-${cultivation.id}`} cultivation={cultivation}/>;
  },
};

export const locationColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'location',
  label: 'Locatie',
  width: '80px',
  filterField: 'fields',
  filterOptionValuesProperty: 'fields',
  groupingField: Planning21Group.PLOT,
  body: (cultivation) =>
    <span
      key={`Locatie-${cultivation.id}`}>{`${cultivation.plot.rotationArea.field.prefix}-${cultivation.plot.rotationArea.number}`}</span>,
};

export const eventsColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'events',
  label: '',
  width: '50px',
  groupingField: Planning21Group.PLOT,
  body: (cultivation) =>
    <EventsColumn cultivation={cultivation} key={`events-${cultivation.id}`}/>
};

export const rotationGroupColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'rotationGroup',
  label: 'Groep',
  width: '100px',
  groupingField: Planning21Group.ROTATION_GROUP,
  filterField: 'rotationGroups',
  filterOptionValuesProperty: 'rotationGroups',
  body: (cultivation) => {
    const startDate = momentFromIso8601(cultivation.startDate);
    const season = SEASONS[`${startDate.year()}`];
    let rotationGroup;
    if (cultivation.plot.rotationArea.rotationSchema) {
      let rotationSchemaForCultivation = cultivation.plot.rotationArea.rotationSchema.find(rotationSchema => {
        return rotationSchema.season === season;
      });
      if (rotationSchemaForCultivation) {
        rotationGroup = rotationSchemaForCultivation.rotationGroup;
      }
    }
    return <span key={`Groep-${cultivation.id}`}>{rotationGroup && rotationGroup.name}</span>;
  },
};

export const cropColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'crop',
  label: 'Gewas',
  width: '200px',
  filterField: 'crops',
  filterOptionValuesProperty: 'crops',
  groupingField: Planning21Group.CROP,
  body: (cultivation) => {
    const {id, varieties, crop, parent} = cultivation;
    let varietiesNames = <div
      style={{display: 'grid', gridTemplateColumns: 'max-content 1fr', gridGap: '8px', alignItems: "center"}}>
      {parent?.id && <i className="pi pi-angle-up"/>}
      {crop.name}
    </div>;

    let varietiesForCultivation = compact(varieties);
    if (varietiesForCultivation.length > 0) {
      varietiesNames = <div className="overflow-hidden" key={`Gewas-${id}`}>
        {(varieties || []).map(cultivationVariety => {
          const {alternativeCropName, name} = cultivationVariety.variety;
          return (
            <div className="flex align-items-center gap-1" key={`Gewas-${id}-${cultivationVariety.id}`}>
              <span
                className="font-bold white-space-nowrap">{crop && (cultivationVariety && alternativeCropName ? alternativeCropName : crop.name)}</span>
              {cultivationVariety && <span
                className="font-italic text-overflow-ellipsis white-space-nowrap text-xs text-color-secondary">{name}</span>}
            </div>
          );
        })}
      </div>;
    }
    return <div
      style={{paddingLeft: `${((momentFromIso8601(cultivation.startDate).isoWeek() / 52) * 100)}px`}}>{varietiesNames}</div>;
  },
};
export const lengthColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'length',
  label: 'Lengte',
  width: '150px',
  body: (cultivation) => {
    let width = cultivation.plot.rotationArea.field.bedWidth;

    const {id, length} = cultivation;
    const plantSchema = plantSchemaForCalculations(cultivation);
    let parentPlantSchema = cultivation.parent ? plantSchemaForCalculations(cultivation.parent) : undefined;
    let nrOfPlants = plantSchema ? plantAmount(plantSchema.plantSchema, length, width, parentPlantSchema?.plantSchema) : undefined;

    const nrOfPlantsValue = (nrOfPlants && nrOfPlants > 0) ? `${nrOfPlants}pl` : '-';
    let rows = plantSchema ? nrOfRows(width, plantSchema.plantSchema.distanceBetweenRows) : 0;
    return <div key={`Lengths-${id}`}
                className="text-sm flex gap-1 align-items-center justify-content-around text-overflow-ellipsis">
      <div className='flex align-items-center gap-2  justify-content-around'>
        <div>{length && `${length}m`}</div>
        <div className="text-xs text-color-secondary">{nrOfPlantsValue}</div>
      </div>
      <div>
        <div className="white-space-nowrap">{plantSchema && `${rows} rij${rows > 0 ? 'en' : ''}`}</div>
        {/*<div*/}
        {/*  className="text-xs text-color-sceondary">{plantSchema && `${plantSchema.plantSchema.distanceBetweenRows}cm x ${plantSchema.plantSchema.distanceInRow}cm`}</div>*/}
      </div>
    </div>
  }
};
export const simpleLengthColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'length',
  label: 'Lengte',
  width: '80px',
  body: ({id, length}) => {
    return <span key={`Lengths-${id}`}>{length && `${length}m`}</span>
  }
};
export const dateColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'date',
  label: 'Datum',
  width: '110px',
  filterField: 'weeks',
  filterOptionValuesProperty: 'weeks',
  body: (cultivation) => {
    const {id} = cultivation;
    const sowingOrPlantingWindow = calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate);
    const harvestWindow = calculateHarvestWindow(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate);
    const minGrowingDays = harvestWindow.firstDay.diff(sowingOrPlantingWindow.firstDay, 'd');
    return <div
      key={`Datum-${id}`}>
      <div>
        {sowingOrPlantingWindow && (`w${sowingOrPlantingWindow.firstDay.isoWeek()} - `)}
        {harvestWindow && `w${harvestWindow.lastDay.isoWeek()}`}
      </div>
      <div className="text-xs text-color-secondary">
        <span>{sowingOrPlantingWindow && (`${asDdMmm(sowingOrPlantingWindow.firstDay)} - `)}</span>
        <span>{harvestWindow && asDdMmm(harvestWindow.lastDay)}</span>
      </div>
      {/*&nbsp;({minGrowingDays}d)*/}
    </div>;
  }
};
export const timelineColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'timeline',
  label: 'Tijdslijn',
  width: '1fr',
  body: (cultivation, selected, onSelectionChange, years) => {
    if (cultivation.id === 'new') {
      return;
    }

    const {id, cropTiming, transplant, crop} = cultivation;


    let timelineData = cropTimingToTimelineData(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate);
    const plantSchema = plantSchemaForCalculations(cultivation);
    return <Timeline
      years={years}
      events={cultivation.events}
      key={`timeline-{id}`}
      bedWidth={cultivation.plot.rotationArea.field.bedWidth}
      bedLength={cultivation.plot.rotationArea.field.bedLength}
      cultivationLength={cultivation.length}
      showFlameweeding={true}
      showPreseeding={cropTiming.type === PlantOrSeed.PLANT && !transplant}
      cropTiming={cropTiming}
      crop={crop}
      plantSchema={plantSchema?.plantSchema}
      occupationRatio={1}
      timelineData={timelineData}
      id={id}
    />;
  },
  subHeaders: (years: number[]) => {
    return flattenDeep(sortBy(years).map(year =>
      range(1, 13)
        .map(t => ({
          key: `${year}-${t - 1}`,
          value: `${year}-${t - 1}`,
          label: nl.monthNamesShort[t - 1],
          width: '1fr'
        }))
    ));
  },
};

export const tasksColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'tasks',
  label: 'Taken',
  width: '50px',
  body: (cultivation) => {
    return <>
      <CultivationTasks cultivation={cultivation}/>
    </>;
  }
};

export const groupColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations> = {
  key: 'cultivationGroup',
  label: '',
  width: '10px',
  body: (cultivation, selected, onSelectionChange, years , groupPosition) => {
    return <div className='relative'>
      {groupPosition === 'first' && <div style={{
        position: 'absolute',
        bottom: 0,
        border: '2px solid black',
        left: 0,
        right: 0,
        top: '50%',
        borderRight: 0,
        borderBottom: 0,
      }}></div>}
      {groupPosition === 'middle' && <div style={{
        position: 'absolute',
        top: 0,
        borderLeft: '2px solid black',
        left: 0,
        right: 0,
        bottom: 0,
      }}></div>}
      {groupPosition === 'last' && <div style={{
        position: 'absolute',
        top: 0,
        border: '2px solid black',
        left: 0,
        right: 0,
        bottom: '50%',
        borderRight: 0,
        borderTop: 0,
      }}></div>}
    </div>;
  },
};
export const PLOT_PLANNING21_COLUMNS: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations>[] = [
  selectionColumn,
  // warningColumn,
  statusColumn,
  groupColumn,
  // //rotationGroupColumn,
  //tasksColumn,
  cropColumn,
  lengthColumn,
  // dateColumn,
  eventsColumn,
  timelineColumn,
];

export const CROP_PLANNING21_COLUMNS: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations>[] = [
  selectionColumn,
  // warningColumn,
  statusColumn,
  locationColumn,
  // rotationGroupColumn,
  cropColumn,
  lengthColumn,
  eventsColumn,
  // dateColumn,
  timelineColumn,
];
export const DATE_PLANNING21_COLUMNS: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations>[] = [
  selectionColumn,
  statusColumn,
  locationColumn,
  cropColumn,
  lengthColumn,
  eventsColumn,
  timelineColumn,
];

export const ROTATION_GROUP_PLANNING21_COLUMNS: Planning21Column<CultivationsForRotationGroup_cultivations | CultivationsForCrop_cultivations | CultivationsForPlot_cultivations>[] = [
  // warningColumn,
  statusColumn,
  locationColumn,
  cropColumn,
  lengthColumn,
  // dateColumn,
  timelineColumn,
];
