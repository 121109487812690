import { Button } from 'primereact/button';
import React, { ReactNode, useEffect, useState } from 'react';
import { ColumnHeaderOptions } from "primereact/column";

interface EditableValueProps {
  value: ReactNode | (() => ReactNode);
  label: string;
  children: (leaveEditMode: () => void) => ReactNode;
  classname?: string;
}

const EditableValue = (props: EditableValueProps) => {
  const [editMode, setEditMode] = useState<boolean>();
  const leaveEditMode = () => setEditMode(false);

  useEffect(() => {
    setEditMode(props.value === null || props.value === undefined);
  }, [props.value]);

  return <div className={`flex align-items-start border-1 border-primary-900 ${props.classname}`}>
    <div className="flex-grow-1">
      <label className="text-xs uppercase text-color-secondary">{props.label}</label>
      <div className="flex align-items-center pb-1 lg:my-1">
        {editMode && props.children(leaveEditMode)}
        {!editMode && (typeof props.value === "function" ? props.value() : <div className="font-bold text-lg">{props.value}</div>)}
      </div>
    </div>
    <div className="p-1">
      {editMode && <Button className="w-1rem p-0 text-xs" size="small" link icon="pi pi-times"
                           onClick={() => setEditMode(false)}/>}
      {!editMode && <Button className="w-1rem p-0 text-xs" size="small" link icon="pi pi-pencil"
                            onClick={() => setEditMode(true)}/>}
    </div>
  </div>;
};

export default EditableValue;
