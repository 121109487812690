import gql from "graphql-tag";

export const SellPlantMutation = gql`
mutation SellPlant($id: ID!, $soldTo: String) {
  updateOrderItem(
    where: {id: $id}
  	data: {soldTo: $soldTo}
  ) {
    id
    soldTo
  }
}`;


export const GetOrderItemsQuery = gql`
query GetOrderItems($farm: ID!) {
  orderItems( where: {
    farm: {id: $farm}
    deliveryYear: 2025
  }) {
     id
    code
    name
    cropName
    amount
    filename
    deliveryWeek
    deliveryDay
    deliveryYear
    soldTo
    soilBlockType
  }
}`;

export const CultivationsForOrdersQuery = gql`
query CultivationsForOrders($farm: ID!) {
  cultivations(
    where: {
      plot: {rotationArea: {field: {location: {farm: {id: $farm}}}}}
      startDate_gt: "2024-12-31"
      startDate_lt: "2025-12-31"
      cropTiming: {
        type: PLANT
      }
      transplant: true
    }
  ) {
    id
    startDate
    length
    plot {
      rotationArea {
        field {
          bedWidth
        }
      }
    }
    varieties {
      id
      percentage
      variety {
        code
        id
        name
        alternativeCropName
        soilBlockType
        supplier {
          name
        }
      }
    }
    plantSchema {
      plantSchema {
        distanceInRow
        distanceBetweenRows
      }
    }
    parent {
      id
      plantSchema {
        plantSchema {
        distanceInRow
        distanceBetweenRows
        }
      }
      crop {
        plantSchemas {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
          default
        }
      }
    }
    crop {
      id
      name
      plantSchemas {
        id
        default
        plantSchema {
          distanceInRow
          distanceBetweenRows
        }
      }
    }
  }
}`;


export const CreateOrderItemMutation = gql`
mutation CreateOrderItem($data: OrderItemCreateInput!) {
  createOrderItem(
    data: $data
  ){
    id
    code
    name
    cropName
    amount
    filename
    deliveryWeek
    deliveryDay
    deliveryYear
   }
}`;

