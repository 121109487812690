import React from 'react';
import {GardenFilterValues} from "../planning21.interfaces";
import {range} from "lodash";
import DateRow from "../rows/DateRow";

interface CropBasedPlanningProps {
  gardenFilters: GardenFilterValues;
}

const CropBasedPlanning = (props: CropBasedPlanningProps) => {

  return <>
    {range(2, 52, 2).map(weekNumber => <DateRow key={weekNumber} weekNumber={weekNumber} filters={props.gardenFilters}/>)}
  </>;
};

export default CropBasedPlanning;
