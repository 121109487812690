import React, {useState} from 'react';
import styled from "styled-components";
import {Planning21HorizontalGrid} from "../styled-components";
import {Planning21Column} from "./planning21-columns";
import AddCultivation from "./AddCultivation/AddCultivation";
import {usePlanning} from "../../planning21-context";
import {nameForPlot} from "../../../../utils/plot.util";
import {CultivationsForCrop_cultivations, CultivationsForPlot_cultivations} from "../../../../__generated__/types";
import classNames from "classnames";
import {get} from "lodash";
import {CURRENT_SEASON} from "../../../../shared/config";
import {momentFromIso8601} from "../../../../shared/utils/date.utils";

export interface CultivationRowProps {
  firstInGroup?: boolean;
  lastInGroup?: boolean;
  inGroup?: boolean;
  groupingColumn: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations>;
  cultivation: CultivationsForCrop_cultivations;
  columns: Planning21Column<CultivationsForCrop_cultivations | CultivationsForPlot_cultivations>[];
  plotId: string;
  // plot: {
  //   id: string,
  //   rotationArea: { number: number, field: { bedLength: number, name: string, prefix: string } },
  // };
  editable: boolean;
  selected?: boolean;
  onSelectionChange?: (selection: boolean) => void;
  cultivations?: CultivationsForPlot_cultivations[];
}

const Row = styled.div<{ replicatedTo: boolean; replicatedFrom: boolean }>`
  position: relative;
    // ${props => props.replicatedTo ? `
  // &::after {
  //   content: '';
  //   position: absolute;
  //   left: 0px;
  //   top: 0;
  //   bottom: 0;
  //   background: green;
  //   font-size: 13px;
  //   bottom: 0;
  //   min-width: 5px;
  //
  // }
  //   &:hover::after {
  //     content: 'Opnieuw ingepland in 2025';
  //     padding: 0 5px;
  //
  //     height: 20px;
  //     z-index: 9999;
  //
  //   }
  // ` : ''}
    // ${props => props.replicatedFrom ? `
  // &::after {
  //   content: '';
  //   position: absolute;
  //   left: 0px;
  //   top: 0;
  //   bottom: 0;
  //   background: green;
  //   font-size: 13px;
  //   bottom: 0;
  //   width: 5px;
  // }
  //
  //   &:hover::after {
  //     content: 'Herhaling van 2024';
  //     padding: 0 5px;
  //     left:30px;
  //
  //     height: 20px;
  //     z-index: 9999;
  //
  //   }
  // ` : ''}
`;

const Container = styled(Planning21HorizontalGrid)`
  border-top: 1px solid var(--surface-d);
  //border-bottom: 1px solid var(--surface-d);
  cursor: pointer;

  &.interPlanting {
    font-style: italic;
    opacity: 0.5;
  }

  &.skipped {
    text-decoration: line-through;
    opacity: 0.1;

    &:hover {
      opacity: 0.8;
    }
  }

  &.isNotInCurrentPlanningSeason {
    opacity: 0.6;
    background: var(--surface-50);
  }
`;

const CultivationRow = (props: CultivationRowProps) => {
  const {years} = usePlanning();

  const [mode, setMode] = useState<'read' | 'edit' | 'create'>('read');

  const {editable, plotId, columns, cultivation} = props;

  if (cultivation.id === 'new') {
    //return <OutsideAlerter onOutsideClicked={() => setMode('read')}>
    return <div>
      <Container
        onClick={() => {
          if (mode === 'read') {
            setMode('create');
          } else {
            setMode('read');
          }
        }}
        className={'cultivationRow'}
        key={`${plotId}-${cultivation.id}`}
        columns={columns}
      >
        {nameForPlot(cultivation.plot)}
      </Container>
      {mode === 'create' &&
        <AddCultivation
          cultivations={props.cultivations}
          plotId={plotId}
          cultivationId={cultivation.id}
          onClose={() => setMode('read')}
        />}
    </div>;
    // </OutsideAlerter>;
  }

  let plantOrSeedEvent = ((cultivation && cultivation.events) || []).find(e => e.type === 'PLANT' || e.type === 'SEED');
  let skipped = false;
  if (plantOrSeedEvent && plantOrSeedEvent.skipped) {
    skipped = true;
  }

  let isNotInCurrentPlanningSeason = !CURRENT_SEASON.startsWith(`${momentFromIso8601(cultivation.startDate).year()}`);
  // return <OutsideAlerter onOutsideClicked={() => setMode('read')}>

  return <Row replicatedTo={!!cultivation.replicatedTo} replicatedFrom={!!cultivation.replicatedFrom}>
    <Container
      onClick={(e) => {
        if (get(e.target, 'className') !== 'p-checkbox-box') {
          if (mode === 'read') {
            editable && setMode('edit');
          } else {
            setMode('read');
          }
        }

      }}
      className={
        classNames(
          'cultivationRow',
          {interPlanting: (cultivation && cultivation.interPlanting)},
          {skipped: skipped},
          {isNotInCurrentPlanningSeason},
          {"shadow-2 border-1 border-primary": mode === "edit"},
        )}
      key={cultivation.id}
      columns={columns}>

      {columns.map((column) => {
        let groupPosition: undefined | 'first' | 'middle' | 'last' = undefined;
        if (props.inGroup) {
          groupPosition = 'middle';
          if (props.firstInGroup) {
            groupPosition = 'first'
          }
          if (props.lastInGroup) {
            groupPosition = 'last'
          }
        }
        return cultivation && column.body && column.body(cultivation, props.selected, props.onSelectionChange, years, groupPosition);
      })}
    </Container>
    {mode === 'edit' &&
      <AddCultivation
        cultivations={props.cultivations}
        plotId={plotId}
        crop={{id: cultivation.crop.id}}
        cultivationId={cultivation ? cultivation.id : undefined}
        onClose={() => {
          setMode('read');
        }}
      />}
  </Row>;
  // </OutsideAlerter>;
};

export default CultivationRow;

