import React, {FunctionComponent} from "react";
import {flatMap, range, times} from "lodash";
import {nrOfRows, plantAmount} from "../../utils/planting.util";
import {Gridder} from "../../__generated__/types";

type PlantSchema = {
  distanceBetweenRows: number;
  distanceInRow: number;
  offset?: boolean;
  gridder?: Gridder | null;
};

type PlantSchemaDrawingProps = {
  plantSchema: PlantSchema;
  size?: string;
  bedLength?: number;
  bedWidth?: number;
  hideNrOfPlants?: boolean;
  plantWidth?: number | null;
  plantLength?: number | null;
};

const DRAWING_CONFIG = {
  width: 100,
  height: 85,
};


const gridderSizes = {
  [Gridder.TWO_WHEELS]: 40,
  [Gridder.THREE_WHEELS]: 25,
  [Gridder.FOUR_WHEELS]: 20,
  [Gridder.SIX_WHEELS]: 14,
};

const PlantSchemaDrawing: FunctionComponent<PlantSchemaDrawingProps> = ({
                                                                          size,
                                                                          hideNrOfPlants,
                                                                          plantSchema,
                                                                          bedLength,
                                                                          bedWidth,
                                                                          plantWidth,
                                                                          plantLength,
                                                                        }) => {
  const {distanceBetweenRows, distanceInRow, offset, gridder} = plantSchema;

  const height = bedWidth ? bedWidth * 100 : DRAWING_CONFIG.height;
  const width = DRAWING_CONFIG.width;
  const rows = nrOfRows(height / 100, distanceBetweenRows);
  const paddingHeight = (height - ((rows - 1) * distanceBetweenRows)) / 2;
  const columns = Math.floor((width) / distanceInRow);
  const paddingWidth = (width - ((columns - 1) * distanceInRow)) / 2;

  const points: {
    color: string;
    plantWidth: number | null | undefined;
    x: number;
    y: number;
    plantLength: number | null | undefined
  }[] = flatMap(range(rows), row =>
    range(columns).map(column => ({
      x: (offset && row % 2 === 1)
        ? (paddingWidth + (column * distanceInRow) + (distanceInRow / 2))
        : (paddingWidth + (column * distanceInRow)),
      y: (paddingHeight + (row * distanceBetweenRows)),
      plantWidth,
      plantLength,
      color: 'blue',
    })));

  let gridderPoints: {
    color: string;
    plantWidth: number | null | undefined;
    x: number;
    y: number;
    plantLength: number | null | undefined
  }[] = [];
  debugger;
  let gridderRows = 0;
  let gridderColumns = 0;
  let gridderRowSize = 0;
  let gridderPaddingHeight = 0;
  let gridderPaddingWidth = 0;

  if (gridder) {
    gridderRowSize = gridderSizes[gridder];
    gridderRows = nrOfRows(height / 100, gridderRowSize);
    gridderColumns = Math.floor((width) / gridderRowSize);

    gridderPaddingHeight = (height - ((gridderRows - 1) * gridderRowSize)) / 2;
    gridderPaddingWidth = (width - ((gridderColumns - 1) * gridderRowSize)) / 2;
    gridderPoints = flatMap(range(gridderRows), row => {
      return range(gridderColumns).map(column => ({
        x: (gridderPaddingWidth + (column * gridderRowSize)),
        y: (gridderPaddingHeight + (row * gridderRowSize)),
        plantWidth,
        plantLength,
        color: 'blue',
      }));
    });
  }


  const nrOfPlants = plantAmount(plantSchema, bedLength || 1, bedWidth || 1, undefined);
  const nrOfPlantsValue = (nrOfPlants && nrOfPlants > 0) ? `${nrOfPlants}pl` : '-';

  return <div>
    <div className="relative" style={{width: size, height: size}}>
      <svg version="1.1"
           className={"w-full h-full"}
           viewBox={`0 0 ${width + 20} ${height + 20}`}
           width={width + 20}
           height={height + 20}
           xmlns="http://www.w3.org/2000/svg"
        //         className="absolute"
      >
        <rect width={width} height={height} fill="var(--surface-d)"
              x={20} y={20}
        />
        <line x1={20} x2={width + 20} y1={10} y2={10} stroke={'var(--surface-d)'}/>
        <text x={width / 2 + 20} y={14} textAnchor={'middle'}
              fill={'var(--text-color)'}>{width / 100}m
        </text>
        <line x1={20} x2={20} y1={10} y2={14} stroke={'var(--surface-d)'}/>
        <line x1={width + 19} x2={width + 19} y1={10} y2={14} stroke={'var(--surface-d)'}/>


        <line x1={10} x2={10} y1={20} y2={height + 20} stroke={'var(--surface-d)'}/>
        <line x1={10} x2={14} y1={20} y2={20} stroke={'var(--surface-d)'}/>
        <line x1={10} x2={14} y1={height + 20} y2={height + 19}
              stroke={'var(--surface-d)'}/>
        <text x={10} y={height / 2 + 20} textAnchor={'middle'} fill={'var(--text-color)'}
              transform="rotate(90 7,55)">{height / 100}m
        </text>


        {range(0, gridderRows).map(gridderRow => {
            return <line key={`gridder-row-${gridderRow}-${distanceBetweenRows}-${distanceInRow}`} x1={20}
                         x2={width + 20}
                         y1={20+(gridderPaddingHeight + (gridderRow * gridderRowSize))}
                         y2={20+(gridderPaddingHeight + (gridderRow * gridderRowSize))} stroke={'green'}/>;
          }
        )}

        {range(0, gridderColumns).map(gridderColumn => {
            return <line key={`gridder-col-${gridderColumn}-${distanceBetweenRows}-${distanceInRow}`}
                         y1={20}
                         y2={height + 20}
                         x1={20+(gridderPaddingWidth + (gridderColumn * gridderRowSize))}
                         x2={20+(gridderPaddingWidth + (gridderColumn * gridderRowSize))} stroke={'green'}/>;
          }
        )}

        {/*{gridderPoints.map(({*/}
        {/*                      x, y,*/}
        {/*                    }) => {*/}
        {/*  return <>*/}
        {/*    <circle key={`${x}-${y}`} cx={x + 20} cy={y + 20} r={3}*/}
        {/*            fill="green"/>*/}
        {/*  </>;*/}
        {/*})}*/}

        {points.map(({
                       x, y,
                       plantWidth,
                       plantLength
                     }) => {
          return <>
            {plantWidth && plantLength &&
              <ellipse key={`plant-${x}-${y}`} rx={plantLength / 2} ry={plantWidth / 2} fill="rgba(255,0,0,0.5)"
                       cx={x + 20} cy={y + 20}
              />}
            <circle key={`${x}-${y}`} cx={x + 20} cy={y + 20} r={5}
                    fill="var(--primary-color)"/>
          </>;
        })}


      </svg>
    </div>
    <div
      className="text-center">{`${!hideNrOfPlants ? nrOfPlantsValue : ''} ${plantSchema && `${plantSchema.distanceBetweenRows}cm x ${plantSchema.distanceInRow}cm`}`}</div>
  </div>
};

export default PlantSchemaDrawing;
