import gql from "graphql-tag";
import { Gridder } from "../../__generated__/types";

export const CultivationTasksFragment = gql`
  fragment CultivationTasks on Cultivation {
    tasks {
      id
      type
      dueDate
    }
  }
`;


export const FieldsQuery = gql`
  query Fields($ids: [ID!]) {
    fields(orderBy: orderNumber_ASC, where: {id_in: $ids, active: true}) {
      id
      name
      orderNumber
      type
      rotationAreas(orderBy: number_ASC, where: {active: true}) {
        id
        number
        rotationSchema {
          id
          season
          rotationGroup {
            id
            name
            cropGroups {
              id
              name
            }
          }
        }
        plots(orderBy: number_ASC, where: {active: true}) {
          id
          number
        }
      }
    }
  }`;

export const PlotsWithCultivationIdsQuery = gql`
  query PlotsWithCultivationIds($farm: ID!, $from: DateTime!, $to: DateTime!) {
    plots(where:
    {
      active: true, rotationArea: {active: true, field: {active: true, location: {farm: {id: $farm}}}}
    }
    ) {
      id
      number
      active
      rotationArea {
        id
        number
        active
        rotationSchema {
          id
          season
          rotationGroup {
            id
            name
            cropGroups {
              id
              name
            }
          }
        }
        field {
          id
          name
          prefix
          bedLength
          bedWidth
          active
          orderNumber
          type
        }
      }
      cultivations(
        orderBy: startDate_ASC,
        where: {
          OR: [
            {startDate_gte: $from, startDate_lte: $to},
            {harvestEndDate_gte: $from, harvestEndDate_lte: $to},
          ]
        }
      ) {
        id
        startDate
        harvestEndDate
        length
        varieties {
          id
        }
        events {
          id
          date
          executionDate
          type
        }
        plot {
          rotationArea{
            field{
              bedWidth
            }
          }
        }
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          plantSchemas {
            default
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
          }
        }
        cropTiming {
          startWeekNumberFrom
          startWeekNumberUntil
          endWeekNumberFrom
          endWeekNumberUntil
        }
      }
    }
  }`;

export const CropsQuery = gql`
  query Crops {
    crops(orderBy: name_ASC) {
      id
      name
      group {
        name
      }
    }
  }`;

export const CropsWithCultivationIdsQuery = gql`
  query CropsWithCultivationIds($farm: ID!, $from: DateTime!, $to: DateTime!) {
    crops(orderBy: name_ASC) {
      id
      name
      cultivations(orderBy: startDate_ASC,
        where: {
          plot: {rotationArea: {field: {location: {farm: {id: $farm}}}}}
          OR: [
            {startDate_gte: $from, startDate_lte: $to},
            {harvestEndDate_gte: $from, harvestEndDate_lte: $to},
          ]
        }
      ) {
        id
        startDate
        harvestEndDate
        length
        varieties {
          id
        }
        events {
          id
          date
          executionDate
          type
        }
        plot {
          rotationArea{
            field{
              bedWidth
            }
          }
        }
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          plantSchemas {
            default
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
          }
        }
        cropTiming {
          startWeekNumberFrom
          startWeekNumberUntil
          endWeekNumberFrom
          endWeekNumberUntil
        }
      }
    }
  }`;

export const RotationGroupsQuery = gql`
  query RotationGroups($farm: ID!) {
    rotationGroups(
      where: {
        farm: {id: $farm}
      }
      orderBy: name_ASC
    ) {
      id
      name
      cropGroups {
        id
        name
      }
    }
  }`;

export const CultivationsForCropQuery = gql`
  ${CultivationTasksFragment}
  query CultivationsForCrop($fields: [ID!]!, $cropId: ID!, $from: DateTime!, $to: DateTime!) {
    crop(where: {id: $cropId}) {
      id
      name
      cropWidth
      cropLength
      group {
        id
        name
      }
      plantSchemas {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
    }
    cultivations(orderBy: startDate_ASC,
      where: {
        plot: {rotationArea: {field: {id_in: $fields}}}
        crop: {id: $cropId}
        OR: [
          {startDate_gte: $from, startDate_lte: $to},
          {harvestEndDate_gte: $from, harvestEndDate_lte: $to},
        ]
      }
    ) {
      ...CultivationTasks
      id
      season
      startDate
      group {
        id
        cultivations {
          id
        }
      }
      harvestEndDate
      pinned
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          cropWidth
          cropLength
          plantSchemas {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
            default
          }
        }
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        active
        number
        rotationArea {
          id
          number
          active
          field {
            id
            active
            name
            orderNumber
            prefix
            bedLength
            bedWidth
            type
          }
          rotationSchema {
            id
            season
            rotationGroup {
              id
              name
              cropGroups {
                id
                name
              }
            }
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        cropWidth
        cropLength
        daysToTransplant
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }`;

export const CultivationsForDateQuery = gql`
  ${CultivationTasksFragment}
  query CultivationsForDate($fields: [ID!]!, $cultivationWhereInput: CultivationWhereInput!) {
    cultivations(orderBy: startDate_ASC,
      where: {
        AND: [
          {
            plot: {rotationArea: {field: {id_in: $fields}}}
          },
          $cultivationWhereInput
        ]

      }
    ) {
      ...CultivationTasks
      id
      season
      startDate
      group {
        id
        cultivations {
          id
        }
      }
      harvestEndDate
      pinned
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          cropWidth
          cropLength
          plantSchemas {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
            default
          }
        }
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        active
        number
        rotationArea {
          id
          number
          active
          field {
            id
            active
            name
            orderNumber
            prefix
            bedLength
            bedWidth
            type
          }
          rotationSchema {
            id
            season
            rotationGroup {
              id
              name
              cropGroups {
                id
                name
              }
            }
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        cropWidth
        cropLength
        daysToTransplant
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }`;

export const CultivationsForRotationGroupQuery = gql`
  ${CultivationTasksFragment}
  query CultivationsForRotationGroup($fields: [ID!]!, $fieldType: FieldType!, $rotationGroupId: ID!, $season: Season!, $from: DateTime!, $to: DateTime!) {
    rotationGroup(where: {id: $rotationGroupId}) {
      id
      name
      rotationSchema {
        season
        rotationArea {
          active
          field {
            name
            active
            bedLength
            bedWidth
          }
          plots(where: {active: true, rotationArea: {field: {type: $fieldType}}}) {
            id
          }
        }
      }
    }
    cultivations(orderBy: startDate_ASC,
      where: {
        plot: {
          rotationArea: {
            field: {
              type: $fieldType
              id_in: $fields
            }
            rotationSchema_some: {
              rotationGroup: {
                id: $rotationGroupId
              }
              season: $season
            }
          }
        }
        OR: [
          {startDate_gte: $from, startDate_lte: $to},
          {harvestEndDate_gte: $from, harvestEndDate_lte: $to},
        ]
      }
    ) {
      ...CultivationTasks
      id
      season
      startDate
      harvestEndDate
      pinned
      group {
        id
        cultivations {
          id
        }
      }
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          cropWidth
          cropLength
          plantSchemas {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
            default
          }
        }
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        active
        number
        rotationArea {
          id
          number
          active
          field {
            id
            active
            name
            orderNumber
            prefix
            bedLength
            bedWidth
            type
          }
          rotationSchema {
            id
            season
            rotationGroup {
              id
              name
              cropGroups {
                id
                name
              }
            }
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        cropWidth
        cropLength
        daysToTransplant
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }`;

export const CultivationsForPlotQuery = gql`
  ${CultivationTasksFragment}
  query CultivationsForPlot($plotId: ID!, $from: DateTime!, $to: DateTime!) {
    plot(where: {id: $plotId}) {
      id
      number
      active
      rotationArea {
        id
        number
        active
        rotationSchema {
          id
          season
          rotationGroup {
            id
            name
            cropGroups {
              id
              name
            }
          }
        }
        field {
          id
          name
          prefix
          bedLength
          bedWidth
          active
          orderNumber
          type
        }
      }
    }
    cultivations(
      orderBy: startDate_ASC,
      where: {
        plot: {id: $plotId}
        OR: [
          {startDate_gte: $from, startDate_lte: $to},
          {harvestEndDate_gte: $from, harvestEndDate_lte: $to},
        ]
      }
    ) {
      ...CultivationTasks
      id
      season
      startDate
      harvestEndDate
      pinned
      group {
        id
        cultivations {
          id
        }
      }
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          cropWidth
          cropLength
          plantSchemas {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
            default
          }
        }
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        active
        number
        rotationArea {
          id
          number
          active
          field {
            id
            active
            name
            orderNumber
            prefix
            bedLength
            bedWidth
            type
          }
          rotationSchema {
            id
            season
            rotationGroup {
              id
              name
              cropGroups {
                id
                name
              }
            }
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        cropWidth
        cropLength
        daysToTransplant
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }`;

export const UpsertVarietyQuery = gql`
  mutation UpsertVariety(
    $id: ID,
    $cropId: ID!,
    $name: String!,
    $alternativeCropName: String,
    $pricePerPlant: Float,
    $code: String,
    $supplierId: ID!,
    $type: PlantOrSeed,
    $orderWeekFrom: Int,
    $orderWeekUntil: Int,
  ) {
    upsertVariety(
      where: {
        id: $id
      }
      update: {
        supplier: {connect: {
          id: $supplierId
        }}
        name: $name
        alternativeCropName: $alternativeCropName
        pricePerPlant: $pricePerPlant
        code: $code
        type: $type
        orderWeekFrom: $orderWeekFrom
        orderWeekUntil: $orderWeekUntil
      }
      create: {
        supplier: {connect: {
          id: $supplierId
        }}
        crop: {connect: {
          id: $cropId
        }}
        name: $name
        alternativeCropName: $alternativeCropName
        pricePerPlant: $pricePerPlant
        code: $code
        type: $type
        orderWeekFrom: $orderWeekFrom
        orderWeekUntil: $orderWeekUntil
      }
    ){
      id
      supplier {
        id
        name
      }
      name
      alternativeCropName
      pricePerPlant
      code
      type
      orderWeekFrom
      orderWeekUntil
    }
  }`;

export const DeleteCultivationQuery = gql`
  mutation DeleteCultivation($id: ID!) {
    deleteManyTasks(where: {cultivation: {id: $id}}){count}
    deleteCultivation(where: {id: $id}){id}
  }`;

export const UpdateDefaultCropPlantSchemaMutation = gql`
  mutation UpdateDefaultCropPlantSchema($cropId: ID!, $cropPlantSchemaId: ID!) {
    updateManyCropPlantSchemas(
      where: {crop: {id: $cropId}, AND: {plantSchema: {id_not: $cropPlantSchemaId}}}
      data: {default: false}
    ){
      count
    }
    updateCropPlantSchema(
      where: {id: $cropPlantSchemaId}
      data: {default: true}
    ){
      crop {
        id
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
    }
  }
`;

export const AddCropPlantSchemaMutation = gql`
  mutation AddCropPlantSchema($cropId: ID!, $plantSchemaId: ID!, $default: Boolean!) {
    createCropPlantSchema(
      data: {
        default: $default
        plantSchema: {
          connect: {
            id: $plantSchemaId
          }
        }
        crop: {
          connect: {
            id: $cropId
          }
        }
      }
    ) {
      crop {
        id
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
    }
  }
`;

export const DeleteCropPlantSchemaMutation = gql`
  mutation DeleteCropPlantSchema($plantSchemaId: ID!) {
    deleteCropPlantSchema(
      where: {
        id: $plantSchemaId
      }
    ) {
      crop {
        id
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
    }
  }
`;

export const DeleteVariety21 = gql`
  mutation DeleteVariety($id: ID!) {
    deleteVariety(where: {id: $id}) {
      id
    }
  }`;

export const DeleteCropTimingMutation = gql`
  mutation DeleteCropTiming($id: ID!) {
    deleteCropTiming(where: {id: $id}) {
      id
    }
  }`;

export const CropTimingUpsert = gql`
  mutation UpsertCropTiming21 (
    $cropTimingId: ID,
    $cropId: ID!,
    $type: PlantOrSeed!,
    $cultivationMethod: String!,
    $minDuration: Int!,
    $maxDuration: Int!,
    $startWeekNumberFrom: Int!,
    $startWeekNumberUntil: Int!,
    $endWeekNumberFrom: Int!,
    $endWeekNumberUntil: Int!,
    $source: Source!,
  ) {
    updateCrop(where: {id: $cropId}
      data: {
        cropTimings: {
          upsert: {
            where: {
              id: $cropTimingId
            }
            update: {
              type: $type
              cultivationMethod: $cultivationMethod
              minDuration: $minDuration
              maxDuration: $maxDuration
              startWeekNumberFrom: $startWeekNumberFrom
              startWeekNumberUntil: $startWeekNumberUntil
              endWeekNumberFrom: $endWeekNumberFrom
              endWeekNumberUntil: $endWeekNumberUntil
              source: $source
            }
            create: {
              type: $type
              cultivationMethod: $cultivationMethod
              minDuration: $minDuration
              maxDuration: $maxDuration
              startWeekNumberFrom: $startWeekNumberFrom
              startWeekNumberUntil: $startWeekNumberUntil
              endWeekNumberFrom: $endWeekNumberFrom
              endWeekNumberUntil: $endWeekNumberUntil
              source: $source
            }
          }
        }
      }
    ) {
      id
      cropTimings {
        id
        type
        cultivationMethod
        minDuration
        maxDuration
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        source
        varieties {
          id
          name
          code
          alternativeCropName
          supplier {
            id
            name
          }
        }
      }
    }
  }
`;

export const CreateCropMutation = gql`
  mutation CreateCrop (
    $groupId: ID!,
    $familyId: ID!,
    $name: String!,
  ) {
    createCrop(
      data: {
        name: $name
        group: {connect: {id: $groupId}}
        family: {connect: {id: $familyId}}
      }
    ){
      id
      notes
      name
      varieties {
        id
        name
        type
        code
        alternativeCropName
        orderWeekFrom
        orderWeekUntil
        supplier {
          id
          name
        }
        pricePerPlant
      }
      group {
        id
        name
        families {
          id
        }
      }
      family {
        id
        name
        groups {
          id
        }
      }
      plantSchemas {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      cropTimings {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
        varieties {
          id
          name
          type
          code
          alternativeCropName
          supplier {
            id
            name
          }
        }
      }
    }
  }
`;

export const MoveCultivationMutation = gql`
  mutation MoveCultivation($cultivationId: ID!, $plotId: ID!) {
    updateCultivation(
      where: {
        id: $cultivationId
      }
      data:{
        plot: {
          connect: {
            id: $plotId
          }
        }
      }
    ) {
      id
      plot {
        id
      }
    }
  }
`;

export const CultivationUpsert = gql`
  mutation UpsertCultivation21(
    $cultivationId: ID,
    $plotId: ID!,
    $pinned: Boolean!,
    $cropId: ID!,
    $season: Season!,
    $startDate: DateTime!,
    $harvestEndDate: DateTime!,
    $cropTimingId: ID!,
    $length: Int!,
    $remark: String,
    $transplant: Boolean,
    $interPlanting: Boolean,
    $parentId: ID,
    $varietiesUpdate: CultivationVarietyUpdateManyInput,
    $varietiesCreate: CultivationVarietyCreateManyInput,
    $plantSchemaUpdate: CropPlantSchemaUpdateOneInput,
    $plantSchemaCreate: CropPlantSchemaCreateOneInput,
    $notesUpdate: NoteUpdateManyWithoutCultivationInput,
    $notesCreate: NoteCreateManyWithoutCultivationInput,
    $tasksUpdate: TaskUpdateManyWithoutCultivationInput,
    $tasksCreate: TaskCreateManyWithoutCultivationInput,
  ) {
    upsertCultivation(
      where: {
        id: $cultivationId
      }
      update: {
        plot: {connect: {
          id: $plotId
        }}
        pinned: $pinned
        crop: {connect: {
          id: $cropId
        }}
        plantSchema: $plantSchemaUpdate
        season: $season
        startDate: $startDate
        harvestEndDate: $harvestEndDate
        length: $length
        remark: $remark
        notes: $notesUpdate
        cropTiming: {connect: {
          id: $cropTimingId
        }}
        transplant: $transplant
        interPlanting: $interPlanting
        parent: {
          connect: {id: $parentId}
        }
        varieties: $varietiesUpdate
        tasks: $tasksUpdate
      }
      create: {
        plot: {connect: {
          id: $plotId
        }}
        crop: {connect: {
          id: $cropId
        }}
        plantSchema: $plantSchemaCreate
        season: $season
        startDate: $startDate
        harvestEndDate: $harvestEndDate
        length: $length
        remark: $remark
        notes: $notesCreate
        cropTiming: {connect: {
          id: $cropTimingId
        }}
        transplant: $transplant
        interPlanting: $interPlanting
        parent: {
          connect: {id: $parentId}
        }
        varieties: $varietiesCreate
        tasks: $tasksCreate
      }
    ){
      id
      season
      startDate
      harvestEndDate
      pinned
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          plantSchemas {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
            default
          }
        }
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        number
        rotationArea {
          id
          number
          field {
            id
            name
            orderNumber
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }
`;

export const CultivationDenseUpsert = gql`
  mutation UpsertCultivationDense(
    $cultivationId: ID,
    $create: CultivationCreateInput!,
    $update: CultivationUpdateInput!,
  ) {
    upsertCultivation(
      where: {
        id: $cultivationId
      }
      update: $update
      create: $create
    ){
      id
      replicatedTo {id}
      replicatedFrom {id}
      season
      startDate
      harvestEndDate
      pinned
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        number
        rotationArea {
          id
          number
          field {
            id
            name
            orderNumber
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }
`;

export const GroupCultivationsUpdate = gql`
  mutation GroupCultivations($ids:[CultivationWhereUniqueInput!]) {
    createCultivationGroup(
      data: {
        cultivations: {
          connect:$ids
        }
      }
    ) {
      id
      cultivations {
        id
        group {
          cultivations {id}
        }
        replicatedTo {id}
        replicatedFrom {id}
        season
        startDate
        harvestEndDate
        pinned
        replicatedFrom {id}
        replicatedTo {id}
        transplant
        interPlanting
        parent {
          id
        }
        remark
        jiraTaskId
        notes {
          id
          text
          type
        }
        events {
          id
          type
          date
          executionDate
          metadata
          skipped
          note {
            id
            text
          }
        }
        plot {
          id
          number
          rotationArea {
            id
            number
            field {
              id
              name
              orderNumber
            }
          }
        }
        cropTiming {
          id
          startWeekNumberFrom
          startWeekNumberUntil
          endWeekNumberFrom
          endWeekNumberUntil
          minDuration
          maxDuration
          cultivationMethod
          type
          source
        }
        plantSchema {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
        crop {
          id
          name
          group {
            id
            name
          }
          plantSchemas {
            id
            default
            description
            plantSchema {
              id
              distanceInRow
              distanceBetweenRows
              offset
              gridder
            }
          }
        }
        varieties {
          id
          percentage
          variety {
            id
            name
            code
            alternativeCropName
          }
        }
        length
      }
    }
  }
`;

export const AddToCultivationGroupUpdate = gql`
  mutation AddToCultivationGroup($id: ID!, $ids:[CultivationWhereUniqueInput!]) {
    updateCultivationGroup(
      where: {id: $id}
      data: {
        cultivations: {
          connect:$ids
        }
      }
    ) {
      id
      cultivations {
        id
        group {
          cultivations {id}
        }
        replicatedTo {id}
        replicatedFrom {id}
        season
        startDate
        harvestEndDate
        pinned
        replicatedFrom {id}
        replicatedTo {id}
        transplant
        interPlanting
        parent {
          id
        }
        remark
        jiraTaskId
        notes {
          id
          text
          type
        }
        events {
          id
          type
          date
          executionDate
          metadata
          skipped
          note {
            id
            text
          }
        }
        plot {
          id
          number
          rotationArea {
            id
            number
            field {
              id
              name
              orderNumber
            }
          }
        }
        cropTiming {
          id
          startWeekNumberFrom
          startWeekNumberUntil
          endWeekNumberFrom
          endWeekNumberUntil
          minDuration
          maxDuration
          cultivationMethod
          type
          source
        }
        plantSchema {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
        crop {
          id
          name
          group {
            id
            name
          }
          plantSchemas {
            id
            default
            description
            plantSchema {
              id
              distanceInRow
              distanceBetweenRows
              offset
              gridder
            }
          }
        }
        varieties {
          id
          percentage
          variety {
            id
            name
            code
            alternativeCropName
          }
        }
        length
      }
    }
  }
`;

export const RemoveFromCultivationGroupUpdate = gql`
  mutation RemoveFromCultivationGroup($id: ID!, $ids:[CultivationWhereUniqueInput!]) {
    updateCultivationGroup(
      where: {id: $id}
      data: {
        cultivations: {
          disconnect:$ids
        }
      }
    ) {
      id
      cultivations {
        id
        group {
          cultivations {id}
        }
        replicatedTo {id}
        replicatedFrom {id}
        season
        startDate
        harvestEndDate
        pinned
        replicatedFrom {id}
        replicatedTo {id}
        transplant
        interPlanting
        parent {
          id
        }
        remark
        jiraTaskId
        notes {
          id
          text
          type
        }
        events {
          id
          type
          date
          executionDate
          metadata
          skipped
          note {
            id
            text
          }
        }
        plot {
          id
          number
          rotationArea {
            id
            number
            field {
              id
              name
              orderNumber
            }
          }
        }
        cropTiming {
          id
          startWeekNumberFrom
          startWeekNumberUntil
          endWeekNumberFrom
          endWeekNumberUntil
          minDuration
          maxDuration
          cultivationMethod
          type
          source
        }
        plantSchema {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
        crop {
          id
          name
          group {
            id
            name
          }
          plantSchemas {
            id
            default
            description
            plantSchema {
              id
              distanceInRow
              distanceBetweenRows
              offset
              gridder
            }
          }
        }
        varieties {
          id
          percentage
          variety {
            id
            name
            code
            alternativeCropName
          }
        }
        length
      }
    }
  }
`;

export const CultivationDenseUpdate = gql`
  mutation UpdateCultivationDense(
    $cultivationId: ID,
    $data: CultivationUpdateInput!,
  ) {
    updateCultivation(
      where: {
        id: $cultivationId
      }
      data: $data
    ){
      id
      replicatedTo {id}
      replicatedFrom {id}
      season
      startDate
      harvestEndDate
      pinned
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        number
        rotationArea {
          id
          number
          field {
            id
            name
            orderNumber
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }
`;

export const GetPlanningDataQuery = gql`
  query PlanningData {
    plantSchemas {
      id
      distanceBetweenRows
      distanceInRow
      gridder
      offset
    }
  }`;

export const AllCropsQuery = gql`
  query AllCropsWithDetails {
    crops {
      id
      notes
      name
      varieties {
        id
        name
        type
        code
        alternativeCropName
        orderWeekFrom
        orderWeekUntil
        supplier {
          id
          name
        }
        pricePerPlant
      }
      group {
        id
        name
        families {
          id
        }
      }
      family {
        id
        name
        groups {
          id
        }
      }
      plantSchemas {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      cropTimings {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
        varieties {
          id
          name
          type
          code
          alternativeCropName
          supplier {
            id
            name
          }
        }
      }
    }
  }`;

export const PlotQuery = gql`
  query GetPlotInfo($id: ID!) {
    plot(where: {id: $id}) {
      id
      number
      rotationArea {
        number
        nextRotationArea {
          id
          plots {
            id
            number
          }
        }
        field {
          name
          bedLength
          bedWidth
        }
      }
    }
  }`;

export const AllSuppliersQuery = gql`
  query AllSuppliers {
    suppliers {
      id
      name
    }
  }`;

export const FiltersDataQuery = gql`
  query FiltersData($farm: ID!) {
    fields(where: {
      active: true
      location: { farm: { id: $farm}}
    }) {
      id
      name
    }
    rotationGroups(where: {
      farm: {id: $farm}
    }) {
      id
      name
    }
  }`;

export const CreateCultivationEventMutation = gql`
  mutation CreateCultivationEvent($data: CultivationEventCreateInput!) {
    createCultivationEvent(
      data: $data
    ) {
      cultivation {
        id
        season
        startDate
        harvestEndDate
        pinned
        replicatedFrom {id}
        replicatedTo {id}
        transplant
        interPlanting
        parent {
          id
          plantSchema {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
          }
          crop {
            plantSchemas {
              plantSchema {
                distanceInRow
                distanceBetweenRows
              }
              default
            }
          }
        }
        remark
        jiraTaskId
        notes {
          id
          text
          type
        }
        events {
          id
          type
          date
          executionDate
          metadata
          skipped
          note {
            id
            text
          }
        }
        plot {
          id
          number
          rotationArea {
            id
            number
            field {
              id
              name
              orderNumber
            }
          }
        }
        cropTiming {
          id
          startWeekNumberFrom
          startWeekNumberUntil
          endWeekNumberFrom
          endWeekNumberUntil
          minDuration
          maxDuration
          cultivationMethod
          type
          source
        }
        plantSchema {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
        crop {
          id
          name
          group {
            id
            name
          }
          plantSchemas {
            id
            default
            description
            plantSchema {
              id
              distanceInRow
              distanceBetweenRows
              offset
              gridder
            }
          }
        }
        varieties {
          id
          percentage
          variety {
            id
            name
            code
            alternativeCropName
          }
        }
        length
      }
    }
  }
`;

export const MoveCultivationsToPlotMutation = gql`
  mutation MoveCultivationsToPlot($plotId: ID!, $cultivationReferences: [CultivationWhereUniqueInput!]!) {
    updatePlot(
      where: {
        id: $plotId
      }
      data: {
        cultivations: {
          connect: $cultivationReferences
        }
      }
    ) {
      cultivations {
        id
      }
    }
  }`;

export const CopyCultivationsToPlotMutation = gql`
  mutation CopyCultivationsToPlot(
    $plotId: ID!
    $createMayCultivationsInput: [CultivationCreateWithoutPlotInput!]!
  ) {
    updatePlot(
      where: {id: $plotId}
      data: {
        cultivations: {
          create: $createMayCultivationsInput
        }
      }
    ) {
      cultivations {
        id
        replicatedFrom {
          id
        }
      }
    }
  }`;


export const CultivationsQuery = gql`
  ${CultivationTasksFragment}
  query Cultivations($ids: [ID!]) {
    cultivations(orderBy: startDate_ASC,
      where: {
        id_in: $ids
      }
    ) {
      ...CultivationTasks
      id
      season
      startDate
      harvestEndDate
      pinned
      replicatedFrom {id}
      replicatedTo {id}
      transplant
      interPlanting
      parent {
        id
        plantSchema {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
        }
        crop {
          plantSchemas {
            plantSchema {
              distanceInRow
              distanceBetweenRows
            }
            default
          }
        }
      }
      remark
      jiraTaskId
      notes {
        id
        text
        type
      }
      events {
        id
        type
        date
        executionDate
        metadata
        skipped
        note {
          id
          text
        }
      }
      plot {
        id
        active
        number
        rotationArea {
          id
          number
          active
          field {
            id
            active
            name
            orderNumber
            prefix
            bedLength
            bedWidth
            type
          }
          rotationSchema {
            id
            season
            rotationGroup {
              id
              name
              cropGroups {
                id
                name
              }
            }
          }
        }
      }
      cropTiming {
        id
        startWeekNumberFrom
        startWeekNumberUntil
        endWeekNumberFrom
        endWeekNumberUntil
        minDuration
        maxDuration
        cultivationMethod
        type
        source
      }
      plantSchema {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
      crop {
        id
        name
        group {
          id
          name
        }
        plantSchemas {
          id
          default
          description
          plantSchema {
            id
            distanceInRow
            distanceBetweenRows
            offset
            gridder
          }
        }
      }
      varieties {
        id
        percentage
        variety {
          id
          name
          code
          alternativeCropName
        }
      }
      length
    }
  }`;
