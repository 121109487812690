import React from 'react';
import {
  CultivationEventCreateWithoutCultivationInput,
  CultivationEventType,
  CultivationsForCrop_cultivations,
  CultivationsForDate_cultivations,
  CultivationsForPlot_cultivations, PlantOrSeed,
  UpdateCultivationDenseVariables
} from "../../../../../__generated__/types";
import {Button} from "primereact/button";
import {calculateHarvestWindow, calculateSowingOrPlantingWindow} from "../../planning21-mutations";
import {asIso8601, momentFromIso8601} from "../../../../../shared/utils/date.utils";
import useUpdateCultivationMutation from "../../../../../hooks/useUpateCultivationMutation";
import {compact, flatten} from "lodash";
import moment from "moment";

interface EventsColumnProps {
  cultivation: CultivationsForCrop_cultivations | CultivationsForDate_cultivations | CultivationsForPlot_cultivations;
}

const EventsColumn = (props: EventsColumnProps) => {
  const {cultivation} = props;
  const [updateCultivationMutation] = useUpdateCultivationMutation();

  const createCultivationEvents = () => {
    const sowingOrPlantingWindow = calculateSowingOrPlantingWindow(cultivation.cropTiming, cultivation.startDate);
    const harvestWindow = calculateHarvestWindow(cultivation.cropTiming, cultivation.startDate, cultivation.harvestEndDate);

    const seedingEvent = (cultivation.events || [])
      .find(event => event.type === CultivationEventType.SEED);

    let events: CultivationEventCreateWithoutCultivationInput[] = compact([
      {
        date: asIso8601(moment(sowingOrPlantingWindow.firstDay).subtract(1, 'week')),
        type: CultivationEventType.BED_PREP,
      },
      {
        date: asIso8601(sowingOrPlantingWindow.firstDay),
        type: cultivation.cropTiming.type === PlantOrSeed.SEED ? CultivationEventType.SEED : CultivationEventType.PLANT,
      },
      {
        date: asIso8601(harvestWindow.firstDay),
        type: CultivationEventType.HARVEST,
      },
      {
        date: asIso8601(harvestWindow.lastDay),
        type: CultivationEventType.HARVEST_END,
      },
      !cultivation.transplant && cultivation.cropTiming.type === PlantOrSeed.PLANT ? {
        date: asIso8601(moment(sowingOrPlantingWindow.firstDay).subtract((cultivation.crop.daysToTransplant || 30), 'days')),
        type: CultivationEventType.PRESEED,
      } : undefined,
    ]);

    const flameWeedEvents = ['Busselwortel', 'Bewaarwortel'].includes(cultivation.crop.name) ? [5, 10].map(offset => {
      const flameweedDay = seedingEvent ? momentFromIso8601(seedingEvent.executionDate).add(offset, 'day') : sowingOrPlantingWindow.firstDay.clone().add(offset, 'day');
      return {
        date: asIso8601(flameweedDay),
        type: CultivationEventType.FLAMEWEED,
      };
    }) : [];
    events = [...events, ...flameWeedEvents];

    const variables: UpdateCultivationDenseVariables = {
      cultivationId: props.cultivation.id,
      data: {
        events: {
          delete: cultivation.events?.map(({id}) => ({id})),
          create: events
        }
      },
    };


    updateCultivationMutation({
      variables,
      refetchQueries: "active",
    }).then(() => {
    });
  };

  return (
    <div>

      <Button className='w-fit p-0 m-0' text icon={'pi pi-refresh'} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        createCultivationEvents();
      }}/>

      {props.cultivation.events?.length}
    </div>
  );
};

export default EventsColumn;
