import { gql } from "@apollo/client";

export const AllCropFamiliesQuery = gql`
query AllCropFamilies {
  cropFamilies {
    id
    name
  }
}
`;

export const AllCropGroupsQuery = gql`
query AllCropGroups {
  cropGroups {
    id
    name
  }
}
`;

export const CropGroupsWithCropsQuery = gql`
query CropGroupsWithCrops {
  cropGroups {
    id
    name
    crops {
      id
      name
    }
  }
}
`;
export const AllCropsQuery = gql`
query AllCrops {
  crops(orderBy: name_ASC) {
    id
    name
    family {
      id
      name
    }
    group {
      id
      name
    }
  }
}
`;

export const CropDetailsQuery = gql`
query CropDetails($id: ID!) {
  crop(where: {id: $id}) {
    id
    notes
    cropLength
    cropWidth
    name
     varieties {
      id
      name
      type
      code
      alternativeCropName
      orderWeekFrom
      orderWeekUntil
      soilBlockType
      gramsPerRowMeter
      supplier {
        id
        name
      }
      pricePerPlant
    }
    group {
      id
      name
      families {
        id
      }
    }
    family {
      id
      name
      groups {
        id
      }
    }
    plantSchemas {
      id
      default
      description
      plantSchema {
        id
        distanceInRow
        distanceBetweenRows
        offset
        gridder
      }
    }
    cropTimings(orderBy:startWeekNumberFrom_ASC) {
      id
      startWeekNumberFrom
      startWeekNumberUntil
      endWeekNumberFrom
      endWeekNumberUntil
      minDuration
      maxDuration
      cultivationMethod
      type
      source
      varieties {
          id
          name
          type
          code
          alternativeCropName
          soilBlockType
          supplier {
              id
              name
          }
      }
    }
  }
}
`;
