import React from 'react';
import {
  AddToCultivationGroupVariables,
  CultivationsForPlot_cultivations,
  GroupCultivationsVariables, RemoveFromCultivationGroupVariables
} from "../../../../__generated__/types";
import styled from "styled-components";
import {Button} from "primereact/button";
import {compact, first, map, uniq} from 'lodash';
import {
  useAddToCultivationGroupMutation,
  useGroupCultivationsMutation, useRemoveFromCultivationGroupMutation
} from "../../../../hooks/useGroupCultivationsMutation";

interface GroupSelectionButtonProps {
  selection: CultivationsForPlot_cultivations[];
  onSubmit: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  grid-gap: 16px;
  padding: 4px;
`;

const GroupSelectionButton = (props: GroupSelectionButtonProps) => {
  const [groupCultivations] = useGroupCultivationsMutation();
  const [addToCultivationGroup] = useAddToCultivationGroupMutation();
  const [removeFromCultivationGroup] = useRemoveFromCultivationGroupMutation();

  const onSubmit = () => {

    if ((cultivationGroupIdsInSelection.length === 2 && cultivationGroupIdsInSelection.includes(undefined))) {
      const cultivationsToAdd = props.selection.filter(c => !c.group);
      const variables: AddToCultivationGroupVariables = {
        id: first(compact(cultivationGroupIdsInSelection)),
        ids: cultivationsToAdd.map(c => ({id: c.id}))
      };
      addToCultivationGroup({variables}).then(() => {
        console.log("grouped");
      });
    } else if ((cultivationGroupIdsInSelection.length === 1 && cultivationGroupIdsInSelection.includes(undefined))) {
      const variables: GroupCultivationsVariables = {
        ids: props.selection.map(c => ({id: c.id}))
      };

      groupCultivations({variables}).then(() => {
        console.log("grouped");
      });
    }

    props.onSubmit();
  };

  const onRemoveFromGroup = () => {
    if(cultivationGroupIdsInSelection.length === 1 && !!cultivationGroupIdsInSelection[0]) {
      const variables: RemoveFromCultivationGroupVariables = {
        id: cultivationGroupIdsInSelection[0],
        ids: props.selection.map(c => ({id: c.id}))
      };

      removeFromCultivationGroup({variables}).then(() => {
        console.log("grouped");
      });
      props.onSubmit();
    }
  };

  const cultivationGroupIdsInSelection = uniq(map(props.selection, 'group.id'));
  console.log(cultivationGroupIdsInSelection);

  if (props.selection.length === 1) {
    if (props.selection[0].group) {
      return <Button
        className="input"
        label="Verwijder uit groep"
        onClick={() => {
          onRemoveFromGroup();
        }}
      />
    }
    return null;
  }
  return <Container>
    <div>Groepeer {props.selection.length} teelt{props.selection.length > 1 ? 'en' : ''}</div>

    <Button
      disabled={(cultivationGroupIdsInSelection.length === 2 && !cultivationGroupIdsInSelection.includes(undefined))}
      className="input"
      label="Groepeer"
      onClick={() => {
        onSubmit();
      }}
    />
  </Container>;
};

export default GroupSelectionButton;
